import {
  Alert,
  Box,
  Button,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  styled,
  TextField,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import ProfileInterface from "../../types/ProfileInterface";
import UserInterface from "../../types/UserInterface";
import "./User.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function UserEditModal(props: {
  nr_sequency: number;
  nm_person: string;
  ds_password: string;
  nr_user: number;
  ds_cpf: string;
  ds_email: string;
  ds_phone: string;
  profile_nr_sequency: number;
  userRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UserInterface[]>>;
  handleCloseEdit: () => void;
  handleClickUpdate: () => void;
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(53, 68, 145, 1)",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const {
    nr_sequency,
    nm_person,
    ds_email,
    ds_password,
    ds_cpf,
    ds_phone,
    profile_nr_sequency,
    userRefetch,
    handleCloseEdit,
    handleClickUpdate,
  } = props;
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const [UserIdInput] = useState(nr_sequency);
  const [UserNomeInput, setUserNomeInput] = useState(nm_person);
  const [UserEmailInput, setUserEmailInput] = useState(ds_email);
  const [UserSenhaInput, setUserSenhaInput] = useState(ds_password);
  const [UserCPFInput, setUserCPFInput] = useState(ds_cpf);
  const [UserPhoneInput, setUserPhoneInput] = useState(ds_phone);
  const [UserProfileInput, setUserProfileInput] = useState(profile_nr_sequency);

  const [UserNomeInputError, setUserNomeInputError] = useState(false);
  const [UserEmailInputError, setUserEmailInputError] = useState(false);
  const [UserSenhaInputError, setUserSenhaInputError] = useState(false);
  const [UserCPFInputError, setUserCPFInputError] = useState(false);
  const [UserPhoneInputError, setUserPhoneInputError] = useState(false);
  const [UserProfileInputError, setUserProfileInputError] = useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleUserNomeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserNomeInputError(false);
      }
      setUserNomeInput(event.target.value);
    },
    []
  );

  const handleUserEmailInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserEmailInputError(false);
      }
      setUserEmailInput(event.target.value);
    },
    []
  );

  const handleUserSenhaInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setUserSenhaInputError(false);
      }
      setUserSenhaInput(event.target.value);
    },
    []
  );

  const handleUserProfileInput = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setUserProfileInputError(false);
      }
      setUserProfileInput(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const [data] = React.useState<string | null>(
    moment().format("DD/MM/yyyy HH:mm")
  );

  const updateUser = useCallback(() => {
    const User = {
      nr_sequency: UserIdInput,
      nm_person: UserNomeInput,
      ds_password: UserSenhaInput,
      ds_token: "",
      nr_status: 1,
      ds_cpf: UserCPFInput,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
      ds_email: UserEmailInput,
      ds_phone: UserPhoneInput,
      profile: {
        nr_sequency: UserProfileInput,
      },
    };

    axios
      .post("http://localhost:8080/registrationservice/user/update", User)
      .then(() => {
        userRefetch();
        handleCloseEdit();
        handleClickUpdate();
      });
  }, [
    UserIdInput,
    UserNomeInput,
    UserSenhaInput,
    UserCPFInput,
    data,
    UserEmailInput,
    UserPhoneInput,
    UserProfileInput,
  ]);

  const maskCPF = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+$/, "$1");
  };

  const maskPhone = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+$/, "$1");
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (UserNomeInput === "") {
      setUserNomeInputError(true);
      setDsMsgInputError("Favor informar o nome do usuário");
      handleClick();
    } else if (UserEmailInput === "") {
      setUserEmailInputError(true);
      setDsMsgInputError("Favor informar o email");
      handleClick();
    } else if (UserSenhaInput === "") {
      setUserSenhaInputError(true);
      setDsMsgInputError("Favor informar a senha");
      handleClick();
    } else if (UserCPFInput.match(/^\S{14}$/) === null) {
      setUserCPFInputError(true);
      setDsMsgInputError("Favor informar o CPF");
      handleClick();
    } else if (UserPhoneInput.match(/^(\S{4})(\s)(\S{10})$/) === null) {
      setUserPhoneInputError(true);
      setDsMsgInputError("Favor informar o telefone");
      handleClick();
    } else if (UserProfileInput === 0) {
      setUserProfileInputError(true);
      setDsMsgInputError("Favor informar o perfil");
      handleClick();
    } else {
      updateUser();
    }
  }, [
    UserNomeInput,
    UserEmailInput,
    UserSenhaInput,
    UserCPFInput,
    UserPhoneInput,
    UserProfileInput,
  ]);

  const { data: repoProfile } = useQuery<ProfileInterface[]>(
    "profile",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/profileuser/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalUser-Main">
        <Box className="ModalUser-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalUser-pTitle">Alterar usuário</p>
            <IconButton onClick={handleCloseEdit}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={UserNomeInputError}
            fullWidth
            onChange={handleUserNomeInput}
            size="small"
            label={<p>Nome</p>}
            defaultValue={UserNomeInput}
            variant="standard"
          ></TextField>
          <TextField
            error={UserEmailInputError}
            fullWidth
            onChange={handleUserEmailInput}
            size="small"
            label={<p>Email</p>}
            defaultValue={UserEmailInput}
            variant="standard"
          ></TextField>
          <TextField
            error={UserSenhaInputError}
            fullWidth
            onChange={handleUserSenhaInput}
            size="small"
            type="password"
            label={<p>Senha</p>}
            defaultValue={UserSenhaInput}
            variant="standard"
          ></TextField>
          <TextField
            error={UserCPFInputError}
            fullWidth
            value={UserCPFInput}
            onChange={useCallback(
              (e: { target: { value: string } }) => {
                if (UserCPFInput !== "") {
                  setUserCPFInputError(false);
                }
                setUserCPFInput(maskCPF(e.target.value));
              },
              [UserCPFInput]
            )}
            size="small"
            label={<p>CPF</p>}
            defaultValue={UserCPFInput}
            variant="standard"
          ></TextField>
          <TextField
            error={UserPhoneInputError}
            fullWidth
            value={UserPhoneInput}
            onChange={useCallback(
              (e: { target: { value: string } }) => {
                if (UserPhoneInput !== "") {
                  setUserPhoneInputError(false);
                }
                setUserPhoneInput(maskPhone(e.target.value));
              },
              [UserPhoneInput]
            )}
            size="small"
            label={<p>Telefone</p>}
            variant="standard"
            defaultValue={UserPhoneInput}
          ></TextField>
          <FormControl fullWidth sx={{textAlign: "left"}}>
            <InputLabel>{<p>Perfil</p>}</InputLabel>
            <Select
              error={UserProfileInputError}
              label={<p>Perfil</p>}
              defaultValue={UserProfileInput}
              onChange={handleUserProfileInput}
              fullWidth
              variant="standard"
            >
              {repoProfile?.map(val => {
                return (
                  <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                    {val.nm_canal}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              onClick={multiFunctions}
              disableRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: blue,
                "&:hover": { backgroundColor: "white", color: blue },
                borderRadius: "10px",
                boxShadow: 5,
                textTransform: "none",
                padding: "0.25em 5em"
              }}
            >
              <p id="ModalUser-pButton">Alterar usuário</p>
            </Button>
          </ThemeProvider>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgInputError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

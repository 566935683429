import { PersonAdd, Logout } from "@mui/icons-material";
import {
  IconButton,
  Avatar,
  Modal,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

export default function SidebarMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/login";
  }, []);

  const handleClickHomepage = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/";
  }, []);

  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{ padding: "0.5em" }}
      >
        <Avatar
          src="../Images/Logos/Incortec_logo_branco.png"
          sx={{
            width: 40,
            height: 40,
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxWidth: "40vw",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0.5em 1em",
            gap: "0.5em",
          }}
        >
          <Avatar />
          <p id="Header-pName">{currentUser}</p>
        </Box>
        <Divider sx={{ margin: "8px 0px" }}/>
        <MenuItem onClick={handleClickHomepage} sx={{ padding: "0.5em 1em" }}>
          <ListItemIcon>
            <HomeOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <p>Home page</p>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickLogout} sx={{ padding: "0.5em 1em" }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <p>Sair</p>
        </MenuItem>
      </Menu>
    </>
  );
}

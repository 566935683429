import React from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Link,
  TextField,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import "./NewsletterPage.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import HomePageInterface from "../../../Portal/types/HomePageInterface";
import HeaderNewsletterPage from "./Header/HeaderNewsletterPage";
import Bottom from "../Bottom";
import FooterPage from "../FooterPage/FooterPage";

export default function NewsletterPage() {
  const mobile = useMediaQuery("(min-width: 901px)");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { data } = useQuery<HomePageInterface[]>(
    "HomePageNewsletter",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/homepagenewsletter/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const { id } = useParams();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100svw",
        height: "100svh",
        flexDirection: "column",
      }}
    >
      <Fab
        sx={{
          backgroundColor: "rgba(53, 68, 145, 1)",
          "&:hover": { backgroundColor: "rgba(53, 68, 100, 1)" },
        }}
        className="NewsletterPage-FloatingButton"
        href="javascript:history.back()"
      >
        <ArrowBackOutlinedIcon id="NewsletterPage-BackIcon" />
      </Fab>

      <Box className="NewsletterPage-Box">
        <HeaderNewsletterPage />
        {data?.map(val => {
          if (val.nr_sequency === parseInt(id ?? "", 10)) {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  padding: mobile ? "0% 10% 2.5em" : "0em 5% 2.5em",
                  gap: "2.5em",
                }}
                key={val.nr_sequency}
              >
                <p id="NewsletterPage-pText">{val.dsConteudo}</p>
                <p id="NewsletterPage-pText">{val.dsConteudo2}</p>
                <p id="NewsletterPage-pText">{val.dsConteudo3}</p>
                <p id="NewsletterPage-pText">{val.dsConteudo4}</p>
                <p id="NewsletterPage-pText">{val.dsConteudo5}</p>
                <Link href={val.dsAdicional} underline="none">
                  <p id="NewsletterPage-pLink">Acesse em: {val.dsAdicional}</p>
                </Link>
              </Box>
            );
          }
          return "";
        })}
        <Bottom />
        <FooterPage />
      </Box>
    </Box>
  );
}

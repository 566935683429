import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import React, { SyntheticEvent } from "react";
import "./Navbar.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, ProSidebarProvider } from "react-pro-sidebar";
import { useHref } from "react-router-dom";

type Anchor = "top";

export default function Navbar() {
  const mobile = useMediaQuery("(min-width: 901px)");

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "auto",
        borderRadius: "0px 0px 5px 5px",
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Button
        sx={{ width: "100%", height: "8vh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#About"
      >
        <p id="Navbar-pButton">SOBRE</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "8vh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#Solutions"
      >
        <p id="Navbar-pButton">SOLUÇÕES</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "8vh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#News"
      >
        <p id="Navbar-pButton">NOVIDADES</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "8vh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#Newsletter"
      >
        <p id="Navbar-pButton">NEWSLETTER</p>
      </Button>
      <Button
        sx={{ width: "100%", height: "8vh", color: "rgba(53, 68, 145, 1)" }}
        disableRipple
        href="#Contact"
      >
        <p id="Navbar-pButton">CONTATO</p>
      </Button>
      <Button
        className="Navbar-LoginButton"
        sx={{ color: "white" }}
        disableRipple
        href="/Login"
      >
        <p id="Navbar-pLoginButton">LOGIN DO PORTAL</p>
      </Button>
    </Box>
  );

  return (
    <>
      {mobile ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box className="Navbar-ExternalBox">
            <Box className="Navbar-InternalBox">
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="#About"
              >
                <p id="Navbar-pButton">SOBRE</p>
              </Button>
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="#Solutions"
              >
                <p id="Navbar-pButton">SOLUÇÕES</p>
              </Button>
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="#News"
              >
                <p id="Navbar-pButton">NOVIDADES</p>
              </Button>
              <IconButton href="#Top">
                <Avatar
                  src="../Images/branding/avatar e destaques/cloup one_avatar.png"
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                />
              </IconButton>
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="#Newsletter"
              >
                <p id="Navbar-pButton">NEWSLETTER</p>
              </Button>
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="#Contact"
              >
                <p id="Navbar-pButton">CONTATO</p>
              </Button>
              <Button
                className="Navbar-Button"
                sx={{
                  color: "rgba(53, 68, 145, 1)",
                  "&:hover": {
                    color: "white",
                  },
                }}
                disableRipple
                href="/Login"
              >
                <p id="Navbar-pButton">PORTAL</p>
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          {(["top"] as const).map(anchor => (
            <React.Fragment key={anchor}>
              <Box className="Navbar-ExternalBox">
                <Button
                  className="Navbar-Button"
                  disableRipple
                  sx={{ color: "rgba(53, 68, 145, 1)", margin: "auto" }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <KeyboardArrowDownIcon fontSize="large" />
                </Button>
              </Box>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </Box>
      )}
    </>
  );
}

import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Button, Box, Alert, Snackbar, IconButton } from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import ProfileInterface from "../../types/ProfileInterface";
import "./ProfileUser.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ProfileUserModalProps {
  profileUserRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ProfileInterface[]>>;
  handleCriarClose: () => void;
  handleClickAdd: () => void;
}

export default function ProfileUserModal({
  profileUserRefetch,
  handleCriarClose,
  handleClickAdd,
}: ProfileUserModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const [ProfileUserNameInput, setProfileUserNameInput] = useState("");
  const [ProfileUserDescrInput, setProfileUserDescrInput] = useState("");

  const [ProfileUserNameInputError, setProfileUserNameInputError] =
    useState(false);
  const [ProfileUserDescrInputError, setProfileUserDescrInputError] =
    useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleProfileUserNameInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserNameInputError(false);
      }
      setProfileUserNameInput(event.target.value);
    },
    []
  );

  const handleProfileUserDescrInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserDescrInputError(false);
      }
      setProfileUserDescrInput(event.target.value);
    },
    []
  );

  const [data] = React.useState<string | null>(
    moment().format("DD/MM/yyyy HH:mm")
  );

  const addProfileUser = useCallback(() => {
    const ProfileUser = {
      nm_canal: ProfileUserNameInput,
      ds_profile: ProfileUserDescrInput,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
    };

    axios
      .post(
        "http://localhost:8080/registrationservice/profileuser/add",
        ProfileUser
      )
      .then(() => {
        profileUserRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  }, [ProfileUserNameInput, ProfileUserDescrInput, data]);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ProfileUserNameInput === "") {
      setProfileUserNameInputError(true);
      setDsMsgInputError("Favor informar o nome do Perfil");
      handleClick();
    } else if (ProfileUserDescrInput === "") {
      setProfileUserDescrInputError(true);
      setDsMsgInputError("Favor informar a descrição");
      handleClick();
    } else {
      addProfileUser();
    }
  }, [ProfileUserNameInput, ProfileUserDescrInput]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalProfileUser-Main">
        <Box className="ModalProfileUser-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalProfileUser-pTitle">Inserir novo perfil</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={ProfileUserNameInputError}
            fullWidth
            onChange={handleProfileUserNameInput}
            size="small"
            label={<p>Nome do perfil</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={ProfileUserDescrInputError}
            fullWidth
            onChange={handleProfileUserDescrInput}
            size="small"
            label={<p>Descrição do perfil</p>}
            variant="standard"
          ></TextField>
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              onClick={multiFunctions}
              disableRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: blue,
                "&:hover": { backgroundColor: "white", color: blue },
                borderRadius: "10px",
                boxShadow: 5,
                textTransform: "none",
                padding: "0.25em 5em",
              }}
            >
              <p id="ModalProfileUser-pButton">Inserir perfil</p>
            </Button>
          </ThemeProvider>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgInputError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

import React, {
  SyntheticEvent,
  useCallback,
  useReducer,
  useState,
} from "react";
import Navbar from "./Navbar/Navbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  IconButton,
  Modal,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import axios from "axios";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import HomePageNovidadesEditModal from "./HomePageNovidadesEditModal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import HomePageInterface from "../../types/HomePageInterface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CollapsibleTable() {
  const Mobile = useMediaQuery("(min-width: 901px)");

  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const [showQuery, setShowQuery] = useState<string>("");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const handleShowQueryState = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowQuery(e.target.value);
    },
    []
  );

  const { data, refetch: HomePageNovidadesRefetch } = useQuery<
    HomePageInterface[]
  >(
    "HomePageNovidades",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/homepagenews/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const textoPesquisa = showQuery ? showQuery : "";

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "92.5svh",
          padding: "0.5em",
          gap: "1em",
        }}
      >
        <Navbar
          handleShowQueryState={handleShowQueryState}
          HomePageNovidadesRefetch={HomePageNovidadesRefetch}
          handleClickAdd={handleClickAdd}
        />
        <Box className="HomePageNovidades-Main">
          <Box className="HomePageNovidades-Wrapper">
            {data?.map(val => {
              if (
                val.dsTitulo.toLowerCase().includes(textoPesquisa.toLowerCase())
              ) {
                return (
                  <Row
                    key={val.nr_sequency}
                    idSelecionado={idSelecionado}
                    setIdSelecionado={setIdSelecionado}
                    row={val}
                    HomePageNovidadesRefetch={HomePageNovidadesRefetch}
                    handleClickDelete={handleClickDelete}
                    handleClickUpdate={handleClickUpdate}
                  />
                );
              }
              return "";
            })}
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          HPS adicionado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          HPS excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          HPS atualizado com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

function Row(props: {
  idSelecionado: number;
  setIdSelecionado: React.Dispatch<React.SetStateAction<number>>;
  row: HomePageInterface;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
  HomePageNovidadesRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<HomePageInterface[]>>;
}) {
  const {
    idSelecionado,
    setIdSelecionado,
    row,
    HomePageNovidadesRefetch,
    handleClickDelete,
    handleClickUpdate,
  } = props;
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [openModal, setOpenModal] = useState(false);
  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";

  const handleRowClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    const idAntigo = idSelecionado;
    const idNovo = row.nr_sequency;
    if (idNovo === idAntigo) {
      setIdSelecionado(0);
    } else {
      setIdSelecionado(idNovo);
    }
  }, []);

  const handleDelete = useCallback(() => {
    fetch(
      "http://localhost:8080/registrationservice/homepagenews/remove/" +
        row.nr_sequency,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        forceUpdate();
        HomePageNovidadesRefetch();
        handleClickDelete();
      });
  }, [row.nr_sequency]);

  const handleCloseEdit = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenModal(true);
  }, []);

  const selecionado = row.nr_sequency === idSelecionado;

  return (
    <>
      <Accordion
        key={row.nr_sequency}
        sx={{
          width: "100%",
          borderRadius: "10px 10px 10px 10px !important",
          boxShadow: 10,
          backgroundColor: "white",
        }}
        disableGutters
        onClick={handleRowClick}
      >
        <AccordionDetails sx={{ padding: "12.5px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                color: blue,
                height: "100%",
              }}
            >
              <p id="HomePageNovidades-pTitle">Título</p>
              <p id="HomePageNovidades-pName">{row.dsTitulo}</p>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: blue,
                height: "100%",
              }}
            >
              <IconButton size="small">
                <ModeIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleOpenEdit}
                />
                <Modal open={openModal} onClose={handleCloseEdit}>
                  <HomePageNovidadesEditModal
                    row={row}
                    handleCloseEdit={handleCloseEdit}
                    HomePageNovidadesRefetch={HomePageNovidadesRefetch}
                    handleClickUpdate={handleClickUpdate}
                  />
                </Modal>
              </IconButton>
              <IconButton size="small">
                <DeleteIcon
                  sx={{
                    color: blue,
                  }}
                  onClick={handleDelete}
                />
              </IconButton>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

import {
  Box,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import "./Navbar.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import dayjs, { Dayjs } from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ActivityModal from "../ActivityModal";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RAT from "../RAT/RAT";

interface NavbarProps {
  ActivityDate: Dayjs | null;
  responseChange: (newActivityDateInput: dayjs.Dayjs) => Promise<void>;
  handleClickAdd: () => void;
  handleCalendar: (newActivityDateInput: dayjs.Dayjs | null) => void;
}

export default function Navbar({
  ActivityDate,
  responseChange,
  handleClickAdd,
  handleCalendar,
}: NavbarProps) {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddActivity = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const PrintRAT = useCallback((): void => {
    setOpenRAT(true);
  }, []);

  const handleRATClose = useCallback((): void => {
    setOpenRAT(false);
  }, []);

  const [openRAT, setOpenRAT] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  return (
    <>
      {Mobile ? (
        <Box className="NavbarActivity-Default">
          <p id="NavbarActivity-pTitle">Atividades</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarActivity-Box">
              <IconButton
                onClick={PrintRAT}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  padding: "0em 0.75em",
                }}
                size="small"
              >
                <PrintOutlinedIcon />
              </IconButton>
              <Modal open={openRAT} onClose={handleRATClose}>
                <RAT handleRATClose={handleRATClose}/>
              </Modal>
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  padding: "0em 0.75em",
                }}
                size="small"
              >
                <CalendarMonthOutlinedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={ActivityDate}
                    onChange={handleCalendar}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Menu>
              <IconButton
                onClick={AddActivity}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarActivity-pButton">Nova atividade</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ActivityModal
                  ActivityDate={ActivityDate}
                  responseChange={responseChange}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarActivity-Default">
          <p id="NavbarActivity-pTitle">Atividades</p>
          <Box className="NavbarActivity-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-Activity"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    "&:hover": { backgroundColor: "#c2c2c2" },
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon
                    sx={{ fontSize: 30 }}
                    color="secondary"
                  />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={ActivityDate}
                  onChange={handleCalendar}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
              <MenuItem onClick={AddActivity} sx={{ ml: 1.1, gap: 1 }}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarActivity-pMenu">Adicionar novo clientes</p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ActivityModal
                  ActivityDate={ActivityDate}
                  responseChange={responseChange}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
              <MenuItem onClick={PrintRAT} sx={{ ml: 1.1, gap: 1 }}>
                <PrintOutlinedIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarActivity-pMenu">Gerar RAT</p>
              </MenuItem>
              <Modal open={openRAT} onClose={handleRATClose}>
                <RAT handleRATClose={handleRATClose}/>
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

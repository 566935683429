import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  Button,
  Box,
  Alert,
  Snackbar,
  MenuItem,
  Select,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  ButtonGroup,
  Modal,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import HomePageInterface from "../../types/HomePageInterface";
import "./HomePageNewsletter.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface HomePageNewsletterModalProps {
  HomePageNewsletterRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<HomePageInterface[]>>;
  handleCriarClose: () => void;
  handleClickAdd: () => void;
}

export default function HomePageNewsletterModal({
  HomePageNewsletterRefetch,
  handleCriarClose,
  handleClickAdd,
}: HomePageNewsletterModalProps) {
  const blue = "rgba(53, 68, 145, 1)";

  const [openConteudo, setOpenConteudo] = useState(false);

  const handleConteudo = useCallback((): void => {
    setOpenConteudo(true);
  }, []);

  const handleConteudoClose = useCallback((): void => {
    setOpenConteudo(false);
  }, []);

  const [HomePageNewsletterTitulo, setHomePageNewsletterTitulo] = useState("");
  const [HomePageNewsletterConteudo1, setHomePageNewsletterConteudo1] =
    useState("");
  const [HomePageNewsletterConteudo2, setHomePageNewsletterConteudo2] =
    useState("");
  const [HomePageNewsletterConteudo3, setHomePageNewsletterConteudo3] =
    useState("");
  const [HomePageNewsletterConteudo4, setHomePageNewsletterConteudo4] =
    useState("");
  const [HomePageNewsletterConteudo5, setHomePageNewsletterConteudo5] =
    useState("");
  const [HomePageNewsletterAdicional, setHomePageNewsletterAdicional] =
    useState("");

  const [HomePageNewsletterTituloError, setHomePageNewsletterTituloError] =
    useState(false);
  const [HomePageNewsletterConteudoError, setHomePageNewsletterConteudoError] =
    useState(false);
  const [
    HomePageNewsletterAdicionalError,
    setHomePageNewsletterAdicionalError,
  ] = useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleHomePageNewsletterTitulo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterTituloError(false);
      }
      setHomePageNewsletterTitulo(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterConteudo1 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterConteudoError(false);
      }
      setHomePageNewsletterConteudo1(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterConteudo2 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterConteudoError(false);
      }
      setHomePageNewsletterConteudo2(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterConteudo3 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterConteudoError(false);
      }
      setHomePageNewsletterConteudo3(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterConteudo4 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterConteudoError(false);
      }
      setHomePageNewsletterConteudo4(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterConteudo5 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterConteudoError(false);
      }
      setHomePageNewsletterConteudo5(event.target.value);
    },
    []
  );

  const handleHomePageNewsletterAdicional = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNewsletterAdicionalError(false);
      }
      setHomePageNewsletterAdicional(event.target.value);
    },
    []
  );

  const [data] = React.useState<string | null>(
    moment().format("DD/MM/yyyy HH:mm")
  );

  const addHomePageNewsletter = useCallback(() => {
    const HomePageNewsletter = {
      dsTitulo: HomePageNewsletterTitulo,
      dsConteudo: HomePageNewsletterConteudo1,
      dsConteudo2: HomePageNewsletterConteudo2,
      dsConteudo3: HomePageNewsletterConteudo3,
      dsConteudo4: HomePageNewsletterConteudo4,
      dsConteudo5: HomePageNewsletterConteudo5,
      dsAdicional: HomePageNewsletterAdicional,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
    };

    axios
      .post(
        "http://localhost:8080/registrationservice/homepagenewsletter/add",
        HomePageNewsletter
      )
      .then(() => {
        HomePageNewsletterRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  }, [
    HomePageNewsletterTitulo,
    HomePageNewsletterConteudo1,
    HomePageNewsletterConteudo2,
    HomePageNewsletterConteudo3,
    HomePageNewsletterConteudo4,
    HomePageNewsletterConteudo5,
    HomePageNewsletterAdicional,
    data,
  ]);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (HomePageNewsletterTitulo === "") {
      setHomePageNewsletterTituloError(true);
      setDsMsgError("Favor informar o título do HPS");
      handleClick();
    } else if (HomePageNewsletterConteudo1 === "") {
      setHomePageNewsletterConteudoError(true);
      setDsMsgError("Favor informar ao menos um parágrafo de conteúdo");
      handleClick();
    } else if (HomePageNewsletterAdicional === "") {
      setHomePageNewsletterAdicionalError(true);
      setDsMsgError("Favor informar o adicional");
      handleClick();
    } else {
      addHomePageNewsletter();
    }
  }, [
    HomePageNewsletterTitulo,
    HomePageNewsletterConteudo1,
    HomePageNewsletterConteudo2,
    HomePageNewsletterConteudo3,
    HomePageNewsletterConteudo4,
    HomePageNewsletterConteudo5,
    HomePageNewsletterAdicional,
    data,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalHomePageNewsletter-Main">
        <Box className="ModalHomePageNewsletter-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNewsletter-pTitle">Inserir novo newsletter</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={HomePageNewsletterTituloError}
            fullWidth
            onChange={handleHomePageNewsletterTitulo}
            size="small"
            label={<p>Título do newsletter</p>}
            variant="standard"
          />
          <Button
            onClick={handleConteudo}
            size="small"
            disableRipple
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              color: HomePageNewsletterConteudoError ? "red" : blue,
              borderColor: HomePageNewsletterConteudoError ? "red" : blue,
              "&:hover": { backgroundColor: blue, color: "white" },
              borderRadius: "10px",
              textTransform: "none",
              padding: "0.25em 5em",
            }}
            fullWidth
          >
            <PostAddOutlinedIcon />
            <p id="ModalHomePageNewsletter-pButton">Adicionar conteúdo</p>
          </Button>
          <Modal open={openConteudo} onClose={handleConteudoClose}>
            <ConteudoModal
              handleHomePageNewsletterConteudo1={
                handleHomePageNewsletterConteudo1
              }
              handleHomePageNewsletterConteudo2={
                handleHomePageNewsletterConteudo2
              }
              handleHomePageNewsletterConteudo3={
                handleHomePageNewsletterConteudo3
              }
              handleHomePageNewsletterConteudo4={
                handleHomePageNewsletterConteudo4
              }
              handleHomePageNewsletterConteudo5={
                handleHomePageNewsletterConteudo5
              }
              HomePageNewsletterConteudo1={HomePageNewsletterConteudo1}
              HomePageNewsletterConteudo2={HomePageNewsletterConteudo2}
              HomePageNewsletterConteudo3={HomePageNewsletterConteudo3}
              HomePageNewsletterConteudo4={HomePageNewsletterConteudo4}
              HomePageNewsletterConteudo5={HomePageNewsletterConteudo5}
              handleConteudoClose={handleConteudoClose}
            />
          </Modal>
          <TextField
            error={HomePageNewsletterAdicionalError}
            fullWidth
            onChange={handleHomePageNewsletterAdicional}
            size="small"
            label={<p>Link</p>}
            variant="standard"
            multiline
            rows={1}
            maxRows={5}
          />
          <Button
            size="small"
            onClick={multiFunctions}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em",
            }}
          >
            <p id="ModalHomePageNewsletter-pButton">Inserir newsletter</p>
          </Button>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function ConteudoModal(props: {
  handleHomePageNewsletterConteudo1: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNewsletterConteudo2: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNewsletterConteudo3: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNewsletterConteudo4: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNewsletterConteudo5: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleConteudoClose: () => void;
  HomePageNewsletterConteudo1: string;
  HomePageNewsletterConteudo2: string;
  HomePageNewsletterConteudo3: string;
  HomePageNewsletterConteudo4: string;
  HomePageNewsletterConteudo5: string;
}) {
  const {
    handleHomePageNewsletterConteudo1,
    handleHomePageNewsletterConteudo2,
    handleHomePageNewsletterConteudo3,
    handleHomePageNewsletterConteudo4,
    handleHomePageNewsletterConteudo5,
    handleConteudoClose,
    HomePageNewsletterConteudo1,
    HomePageNewsletterConteudo2,
    HomePageNewsletterConteudo3,
    HomePageNewsletterConteudo4,
    HomePageNewsletterConteudo5
  } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box className="ModalHomePageNewsletter-Main">
        <Box className="ModalHomePageNewsletter-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNewsletter-pTitle">Conteúdo</p>
            <IconButton onClick={handleConteudoClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>1° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNewsletterConteudo1}
                defaultValue={HomePageNewsletterConteudo1}
                size="small"
                label={<p>Conteúdo do newsletter</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>2° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNewsletterConteudo2}
                defaultValue={HomePageNewsletterConteudo2}
                size="small"
                label={<p>Conteúdo do newsletter</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>3° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNewsletterConteudo3}
                defaultValue={HomePageNewsletterConteudo3}
                size="small"
                label={<p>Conteúdo do newsletter</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>4° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNewsletterConteudo4}
                defaultValue={HomePageNewsletterConteudo4}
                size="small"
                label={<p>Conteúdo do newsletter</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>5° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNewsletterConteudo5}
                defaultValue={HomePageNewsletterConteudo5}
                size="small"
                label={<p>Conteúdo do newsletter</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}

import { Box, Card, Divider, Icon, useMediaQuery } from "@mui/material";
import React from "react";
import "./About.css";

export default function About() {
  const mobile = useMediaQuery("(min-width: 901px)");
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="About-OutsideCard">
        <Box className="About-Box">
          <p id="About-pTitle">SOBRE NÓS</p>
          <Box
            sx={{
              backgroundColor: "rgba(53, 68, 145, 1)",
              height: "0.25em",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <Box className="About-TextBox">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            gap: mobile ? "2.5em" : "1.5em",
          }}
        >
          <p id="About-pText">
            A Cloup One é uma empresa nacional de Tecnologia da Informação, que
            atua com consultoria e desenvolvimento de soluções de TI, bem como,
            fábrica de software, desenvolvimento de aplicações WEB e mobile,
            outsourcing de recursos e implementação de soluções. Nossa equipe é
            conhecida pelo alto grau de especialização e conhecimento atuando no
            desenvolvimento de soluções tecnológicas abrangentes com alta
            capacidade de integração do mundo core com o mundo digital
            respondendo aos desafios da transformação digital.
          </p>
          <p id="About-pTextFooter">
            ‟Integridade, confiança e respeito são os valores que nos guiam.”
          </p>
        </Box>
      </Box>
    </Box>
  );
}

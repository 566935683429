import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import {
  createTheme,
  Button,
  Box,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import ScheduleInterface from "../../types/ScheduleInterface";
import CompanyInterface from "../../types/CompanyInterface";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UserInterface from "../../types/UserInterface";
import DatePicker, { DateObject } from "react-multi-date-picker";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ModalProps {
  handleCriarClose: () => void;
  scheduleList: (month: number, idUser: number) => Promise<void>;
  handleClickAdd: () => void;
  ScheduleUserInput: number;
}

export default function ScheduleModal({
  handleCriarClose,
  scheduleList,
  handleClickAdd,
  ScheduleUserInput,
}: ModalProps) {
  const mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const { currentUserId } = useContext(CurrentUserContext);
  const [ScheduleDescriptionInput, setScheduleDescriptionInput] = useState("");
  const [ScheduleIni, setScheduleIni] = React.useState<Dayjs | null>(null);
  const [ScheduleFin, setScheduleFin] = React.useState<Dayjs | null>(null);
  const [ScheduleCompanyInput, setScheduleCompanyInput] = useState(0);
  const [scheduleDate, setScheduleDate] = useState([
    new DateObject(),
    new DateObject().add(1, "days"),
  ]);

  const [ScheduleDescriptionInputError, setScheduleDescriptionInputError] =
    useState(false);
  const [ScheduleIniError, setScheduleIniError] = useState(false);
  const [ScheduleFinError, setScheduleFinError] = useState(false);
  const [ScheduleCompanyInputError, setScheduleCompanyInputError] =
    useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleScheduleDescriptionInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setScheduleDescriptionInputError(false);
      }
      setScheduleDescriptionInput(event.target.value);
    },
    []
  );

  const handleClienteInput = useCallback((event: SelectChangeEvent<number>) => {
    if (event.target.value !== 0) {
      setScheduleCompanyInputError(false);
    }
    setScheduleCompanyInput(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  // const handleUserInput = useCallback((event: SelectChangeEvent<number>) => {
  //   setScheduleUserInput(parseInt(event.target.value.toString(), 10) ?? 0);
  //   scheduleList(parseInt(event.target.value.toString(), 10) ?? 0);
  // }, []);

  function handleDateChange(value: any) {
    setScheduleDate(value);
  }

  const [data] = React.useState<string | null>(moment().format("DD/MM/YYYY"));

  const addSchedule = () => {
    const Schedule = {
      ds_schedule: ScheduleDescriptionInput,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
      dt_ini: scheduleDate[0]?.format("DD/MM/YYYY"),
      dt_fin: scheduleDate[1]?.format("DD/MM/YYYY"),
      user: {
        nr_sequency: currentUserId,
      },
      company: {
        nr_sequency: ScheduleCompanyInput,
      },
    };

    axios
      .post("http://localhost:8080/registrationservice/schedule/add", Schedule)
      .then(() => {
        handleClickAdd();
        scheduleList(
          dayjs(scheduleDate[0]?.format("DD/MM/YYYY"), "DD/MM/YYYY").month(),
          ScheduleUserInput
        );
        handleCriarClose();
      });
  };

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            addSchedule();
          }
        }}
      >
        <Box className="ModalSchedule-Main">
          <Box className="ModalSchedule-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalSchedule-pTitle">Inserir novo agendamento</p>
              <IconButton onClick={handleCriarClose}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: mobile ? "row" : "column",
                alignItems: "center",
                width: "100%",
                gap: "1em",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="rmdp-container"
                  value={scheduleDate}
                  onChange={handleDateChange}
                  range
                  format="DD/MM/YYYY"
                  render={
                    <TextField
                      label={<p>Tempo da jornada</p>}
                      variant="standard"
                      color="primary"
                      fullWidth
                    />
                  }
                />
              </LocalizationProvider>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel>
                  <p>Cliente</p>
                </InputLabel>
                <Select
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    textAlign: "left",
                  }}
                  fullWidth
                  size="small"
                  color="primary"
                  variant="standard"
                  onChange={handleClienteInput}
                >
                  {repoClientes?.map(val => {
                    return (
                      <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                        {val.nm_company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <TextField
              size="small"
              color="primary"
              variant="standard"
              multiline
              label={<p>Descrição</p>}
              maxRows={2}
              rows={2}
              fullWidth
              onChange={handleScheduleDescriptionInput}
            />
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={addSchedule}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em"
                }}
              >
                <p id="ModalSchedule-pButton">Inserir agendamento</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgInputError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

import { Box, CardActionArea, Card } from "@mui/material";
import React from "react";
import "./News.css";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import axios from "axios";
import { useQuery } from "react-query";
import HomePageInterface from "../../../Portal/types/HomePageInterface";

export default function News() {
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const { data } = useQuery<HomePageInterface[]>(
    "HomePageNews",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/homepagenews/list"
      );

      return response.data;
    },
    {
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="News-OutsideCard">
        <Box className="News-Box">
          <p id="News-pTitle">NOVIDADES</p>
          <Box
            sx={{ backgroundColor: "white", height: "0.25em", width: "40%" }}
          />
          <Box className="News-GroupCard">
            {data?.map(val => {
              if (
                val.nr_sequency === data.length ||
                val.nr_sequency === data.length - 1 ||
                val.nr_sequency === data.length - 2
              ) {
                return (
                  <Card className="News-Card" key={val.nr_sequency}>
                    <CardActionArea
                      href={"NewsPage/" + val.nr_sequency}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Box className="News-CardBox">
                        <p id="News-pTitleCard">{val.dsTitulo}</p>
                        <KeyboardArrowRightOutlinedIcon id="News-ArrowIcon" />
                      </Box>
                    </CardActionArea>
                  </Card>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

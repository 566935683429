import {
  IconButton,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Modal,
  InputBase,
  Paper,
  Icon,
  Divider,
  Menu,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import React, { useCallback, useState } from "react";
import "./Navbar.css";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import CompanyInterface from "../../../types/CompanyInterface";
import CompanyModal from "../CompanyModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

interface NavbarProps {
  handleShowQueryState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  companyRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<CompanyInterface[]>>;
  handleClickAdd: () => void;
}

export default function Navbar({
  handleShowQueryState,
  companyRefetch,
  handleClickAdd,
}: NavbarProps) {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddCompany = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  return (
    <>
      {Mobile ? (
        <Box className="NavbarCompany-Default">
          <p id="NavbarCompany-pTitle">Clientes</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarCompany-Box">
              <Paper
                sx={{
                  bgcolor: "white",
                  p: "2px 4px",
                  boxShadow: 5,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontWeight: 500,
                  }}
                  placeholder="Pesquisar"
                  onChange={handleShowQueryState}
                />
                <SearchOutlinedIcon color="primary" />
              </Paper>
              <IconButton
                onClick={AddCompany}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarCompany-pButton">Novo cliente</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <CompanyModal
                  companyRefetch={companyRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarCompany-Default">
          <p id="NavbarCompany-pTitle">Clientes</p>
          <Box className="NavbarCompany-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-Company"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    "&:hover": { backgroundColor: "#c2c2c2" },
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon
                    sx={{ fontSize: 30 }}
                    color="secondary"
                  />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "white",
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={AddCompany} sx={{ ml: 1.1, gap: 1 }}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarCompany-pMenu">Adicionar novo clientes</p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <CompanyModal
                  companyRefetch={companyRefetch}
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                />
              </Modal>
              <Divider />
              <MenuItem>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: "white",
                    p: "2px 4px",
                    display: "flex",
                    "&& .Mui-selected": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <InputBase
                    sx={{
                      ml: 1,
                      flex: 1,
                      fontFamily: "Montserrat, sans-serif",
                      color: "rgba(53, 68, 145, 1",
                      "&& .Mui-selected": {
                        backgroundColor: "white",
                      },
                    }}
                    placeholder="Pesquisar"
                    onChange={handleShowQueryState}
                  />
                  <ThemeProvider theme={theme}>
                    <IconButton
                      className="SearchButton-Company"
                      color="secondary"
                      sx={{
                        "&:hover": { backgroundColor: "rgb(59 130 246)" },
                        borderRadius: "50%",
                      }}
                    >
                      <SearchOutlinedIcon color="primary" />
                    </IconButton>
                  </ThemeProvider>
                </Paper>
              </MenuItem>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  styled,
  createTheme,
  Button,
  Modal,
  Alert,
  Snackbar,
  useStepContext,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useQuery } from "react-query";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import Loading from "../../../layout/Loader/Loading";
import UserInterface from "../../../types/UserInterface";
import { CurrentUserContext } from "../../../contexts/ContextProvider";
import { permGeral } from "../../../../../App";
import "./RAT.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const ReportButton = styled(Button)({
  textTransform: "none",
  color: "white",
  fontFamily: "Trebuchet MS, sans-serif",
});

interface InterfaceRAT {
  handleRATClose: () => void;
}

export default function RAT({ handleRATClose }: InterfaceRAT) {
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [isLoading, setIsloading] = useState(false);

  const { permission, currentUserId, currentUser } = useContext(CurrentUserContext);

  const [reportUserInput, setReportUserInput] = useState(0);
  const [reportDateIniInput, setReportDateIniInput] = useState<Dayjs | null>(
    null
  );
  const [reportDateFinInput, setReportDateFinInput] = useState<Dayjs | null>(
    null
  );

  const [reportUserInputError, setReportUserInputError] = useState(false);
  const [reportDateIniInputError, setReportDateIniInputError] = useState(false);
  const [reportDateFinInputError, setReportDateFinInputError] = useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleReportUserInput = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setReportUserInputError(false);
      }
      setReportUserInput(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const handleReportDateIniInput = useCallback(
    (newReportDateIniInput: Dayjs | null) => {
      if (newReportDateIniInput !== null) {
        setReportDateIniInputError(false);
      }
      setReportDateIniInput(newReportDateIniInput);
    },
    []
  );

  const handleReportDateFinInput = useCallback(
    (newReportDateFinInput: Dayjs | null) => {
      if (newReportDateFinInput !== null) {
        setReportDateFinInputError(false);
      }
      setReportDateFinInput(newReportDateFinInput);
    },
    []
  );

  const { data: repoUsers } = useQuery<UserInterface[]>(
    "user",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/user/list"
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const loadClose = useCallback(() => {
    setIsloading(false);
  }, []);

  const [open, setOpen] = useState(false);
  const [openRAT, setOpenRAT] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseRAT = useCallback(() => {
    setOpenRAT(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (permission === permGeral) {
      if (reportDateIniInput == null) {
        setReportDateIniInputError(true);
        setDsMsgInputError("Favor informar a data inicial");
        handleClick();
      } else if (reportDateFinInput == null) {
        setReportDateFinInputError(true);
        setDsMsgInputError("Favor informar a data final");
        handleClick();
      } else {
        criarRelatorio();
      }
    } else {
      if (reportUserInput === 0) {
        setReportUserInputError(true);
        setDsMsgInputError("Favor informar o usuário");
        handleClick();
      } else if (reportDateIniInput == null) {
        setReportDateIniInputError(true);
        setDsMsgInputError("Favor informar a data inicial");
        handleClick();
      } else if (reportDateFinInput == null) {
        setReportDateFinInputError(true);
        setDsMsgInputError("Favor informar a data final");
        handleClick();
      } else {
        criarRelatorio();
      }
    }
  }, [reportUserInput, reportDateIniInput, reportDateFinInput]);

  const criarRelatorio = useCallback(() => {
    setIsloading(true);
    const relatorio = {
      usuario: permission === permGeral ? currentUserId : reportUserInput,
      dataInicial: reportDateIniInput?.format("YYYY-MM-DD").toString(),
      dataFinal: reportDateFinInput?.format("YYYY-MM-DD").toString(),
    };
    if (
      (relatorio.usuario && relatorio.dataInicial && relatorio.dataFinal) !=
      null
    ) {
      axios
        .get(
          `http://localhost:8080/registrationservice/pdf?userId=${relatorio.usuario?.toString()}&startDate=${
            relatorio.dataInicial
          }&endDate=${relatorio.dataFinal}`,
          { responseType: "blob" }
        )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `RAT - ${currentUser} - Período de ${reportDateIniInput
              ?.format("DD-MM-YYYY")
              .toString()} até ${reportDateFinInput
              ?.format("DD-MM-YYYY")
              .toString()}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          loadClose();
          setOpenRAT(true);
        });
    }
  }, [reportUserInput, reportDateIniInput, reportDateFinInput]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Box className="ModalRAT-Main">
            <Box className="ModalRAT-Wrapper">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <p id="ModalRAT-pTitle">Gerar RAT</p>
                <IconButton onClick={handleRATClose}>
                  <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
                </IconButton>
              </Box>
              {permission === permGeral ? (
                ""
              ) : (
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <InputLabel>Usuário</InputLabel>
                  <Select
                    variant="standard"
                    error={reportUserInputError}
                    label={<p>Usuário</p>}
                    onChange={handleReportUserInput}
                  >
                    {repoUsers?.map(val => {
                      return (
                        <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                          {val.ds_email}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "1em",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={<p>De</p>}
                    value={reportDateIniInput}
                    onChange={handleReportDateIniInput}
                    inputFormat={"DD/MM/YYYY"}
                    renderInput={useCallback(
                      params => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={reportDateIniInputError}
                          color="primary"
                          fullWidth
                        />
                      ),
                      [reportDateIniInputError]
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={<p>Até</p>}
                    value={reportDateFinInput}
                    onChange={handleReportDateFinInput}
                    inputFormat={"DD/MM/YYYY"}
                    renderInput={useCallback(
                      params => (
                        <TextField
                          {...params}
                          variant="standard"
                          error={reportDateFinInputError}
                          color="primary"
                          fullWidth
                        />
                      ),
                      [reportDateFinInputError]
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <ReportButton
                disableRipple
                id="ModalRAT-Button"
                variant="contained"
                onClick={multiFunctions}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p id="ModalRAT-pButton">Imprimir RAT</p>
              </ReportButton>
              <Modal open={isLoading} onClose={loadClose}>
                <Loading />
              </Modal>
            </Box>
          </Box>
          <Snackbar
            open={openRAT}
            onClick={handleCloseRAT}
            autoHideDuration={2000}
          >
            <Alert severity="success" variant="filled">
              arquivo de RAT criado com sucesso
            </Alert>
          </Snackbar>
          <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
            <Alert severity="error" variant="filled">
              {dsMsgInputError}
            </Alert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    </>
  );
}

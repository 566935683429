import React, { useCallback, useContext, useState } from "react";
import {
  Alert,
  Box,
  createTheme,
  Snackbar,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ActivityTable, { Row } from "./ActivityTable";
import ActivityInterface from "../../types/ActivityInterface";
import axios from "axios";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import Navbar from "./Navbar/Navbar";
import "./Activity.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default function Activity() {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;

  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;

  const [rows, setRows] = useState<Row[]>([]);
  const [Activities, setActivities] = useState<ActivityInterface[]>();

  const [ActivityDate, setActivityDate] = React.useState<Dayjs>(dayjs());

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  const handleCalendar = (newActivityDateInput: dayjs.Dayjs | null) => {
    setActivityDate(newActivityDateInput!);
    responseChange(newActivityDateInput!);
  };

  const responseChange = async (newActivityDateInput: dayjs.Dayjs) => {
    await axios
      .get<ActivityInterface[]>(
        "http://localhost:8080/registrationservice/activity/list"
      )
      .then(res => {
        const filtrado = res.data.filter(
          filter =>
            newActivityDateInput.format("DD/MM/YYYY") === filter.dt_activity &&
            loggedUserId === filter.user.nr_sequency
        );
        const data = filtrado.map(activity => ({
          id: activity.id,
          dt_activity: activity.dt_activity,
          ds_activity: activity.ds_activity,
          dt_time_ini: activity.dt_time_ini,
          dt_time_fin: activity.dt_time_fin,
          company_nr_sequency: activity.company.nr_sequency,
          nm_company: activity.company.nm_company,
        }));
        setRows(data);
        setActivities(res.data);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: Mobile ? "100%" : "92.5svh",
          padding: "0.5em",
          gap: "1em",
        }}
      >
        <Navbar
          ActivityDate={ActivityDate}
          handleCalendar={handleCalendar}
          responseChange={responseChange}
          handleClickAdd={handleClickAdd}
        />
        <Box className="Activity-Wrapper">
          <ThemeProvider theme={theme}>
            <ActivityTable
              ActivityDate={ActivityDate}
              responseChange={responseChange}
              activities={Activities}
              rows={rows}
              handleClickDelete={handleClickDelete}
              handleClickUpdate={handleClickUpdate}
            />
          </ThemeProvider>
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Atividade adicionada com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Atividade excluída com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Atividade atualizada com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

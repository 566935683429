import {
  Alert,
  Box,
  Button,
  createTheme,
  IconButton,
  Snackbar,
  styled,
  TextField,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import ProfileInterface from "../../types/ProfileInterface";
import "./ProfileUser.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function ProfileUserEditModal(props: {
  nr_sequency: number;
  nm_canal: string;
  ds_profile: string;
  handleCloseEdit: () => void;
  profileUserRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ProfileInterface[]>>;
  handleClickUpdate: () => void;
}) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(53, 68, 145, 1)",
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const {
    nr_sequency,
    nm_canal,
    ds_profile,
    handleCloseEdit,
    profileUserRefetch,
    handleClickUpdate,
  } = props;
  const blue = "rgba(53, 68, 145, 1)";
  const [ProfileUserSequenceInput] = useState(nr_sequency);
  const [ProfileUserNameInput, setProfileUserNameInput] = useState(nm_canal);
  const [ProfileUserDescrInput, setProfileUserDescrInput] =
    useState(ds_profile);

  const [ProfileUserNameInputError, setProfileUserNameInputError] =
    useState(false);
  const [ProfileUserDescrInputError, setProfileUserDescrInputError] =
    useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleProfileUserNameInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserNameInputError(false);
      }
      setProfileUserNameInput(event.target.value);
    },
    []
  );

  const handleProfileUserDescrInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setProfileUserDescrInputError(false);
      }
      setProfileUserDescrInput(event.target.value);
    },
    []
  );

  const [data] = React.useState<string | null>(
    moment().format("DD/MM/yyyy HH:mm")
  );

  const updateProfileUser = () => {
    const ProfileUser = {
      nr_sequency: ProfileUserSequenceInput,
      nm_canal: ProfileUserNameInput,
      ds_profile: ProfileUserDescrInput,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
    };

    axios
      .post(
        "http://localhost:8080/registrationservice/profileuser/update",
        ProfileUser
      )
      .then(() => {
        profileUserRefetch();
        handleCloseEdit();
        handleClickUpdate();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ProfileUserNameInput === "") {
      setProfileUserNameInputError(true);
      setDsMsgInputError("Favor informar o nome do Perfil");
      handleClick();
    } else if (ProfileUserDescrInput === "") {
      setProfileUserDescrInputError(true);
      setDsMsgInputError("Favor informar a descrição");
      handleClick();
    } else {
      updateProfileUser();
    }
  }, [ProfileUserNameInput, ProfileUserDescrInput]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalProfileUser-Main">
        <Box className="ModalProfileUser-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalProfileUser-pTitle">Alterar perfil</p>
            <IconButton onClick={handleCloseEdit}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={ProfileUserNameInputError}
            fullWidth
            defaultValue={ProfileUserNameInput}
            onChange={handleProfileUserNameInput}
            size="small"
            label={<p>Nome do perfil</p>}
            variant="standard"
          ></TextField>
          <TextField
            error={ProfileUserDescrInputError}
            fullWidth
            defaultValue={ProfileUserDescrInput}
            onChange={handleProfileUserDescrInput}
            size="small"
            label={<p>Descrição do perfil</p>}
            variant="standard"
          ></TextField>
          <Button
            size="small"
            onClick={multiFunctions}
            disableRipple
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: blue,
              "&:hover": { backgroundColor: "white", color: blue },
              borderRadius: "10px",
              boxShadow: 5,
              textTransform: "none",
              padding: "0.25em 5em"
            }}
          >
            <p id="ModalProfileUser-pButton">Alterar perfil</p>
          </Button>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgInputError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

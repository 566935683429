import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import "./Loading.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});

export default function Loading() {
  return (
    <ThemeProvider theme={theme}>
      <Box className="LoadBox">
        <CircularProgress color="primary" size={100}></CircularProgress>
      </Box>
    </ThemeProvider>
  );
}

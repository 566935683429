import { ThemeProvider } from "@emotion/react";
import {
  Menu,
  MenuItem,
  Sidebar,
  sidebarClasses,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import React, { useCallback, useContext, useState } from "react";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import "./Sidebar.css";
import { createTheme } from "@mui/material/styles";
import { SidebarData, SidebarHomePageMenu, SidebarRegisterMenu } from "./SidebarData";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Modal,
  Tooltip,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SidebarMenu from "./SidebarMenu";

export const SidebarNew = () => {
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseEdit = useCallback((): void => {
    setOpenModal(false);
  }, []);

  const handleOpenEdit = useCallback((): void => {
    setOpenModal(true);
  }, []);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserId");
    localStorage.removeItem("permission");
    window.location.href = "/login";
  }, []);

  const { currentUser } = useContext(CurrentUserContext);

  const AddUser = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";
  const gray = "rgb(226, 226, 226)";
  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#000000",
      },
    },
  });
  const { permission } = useContext(CurrentUserContext);
  const { collapseSidebar, collapsed } = useProSidebar();

  return (
    <ThemeProvider theme={theme}>
      <Sidebar
        className="Sidebar"
        width="15vw"
        rootStyles={{
          height: "100%",
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "transparent",
            borderRadius: "0px 15px 15px 0px",
            borderColor: "white",
          },
          [`.${sidebarClasses.root}`]: {
            borderRadius: "0px 15px 15px 0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "auto",
              width: "100%",
              justifyContet: "space-between",
            }}
          >
            {collapsed ? (
              ""
            ) : (
              <img
                src="../Images/Logos/Incortec_nome_brancos.png"
                alt="logo"
                id="Sidebar-Image"
              />
            )}
            <Button
              onClick={useCallback(() => collapseSidebar(), [])}
              sx={{
                "&:hover": { backgroundColor: "transparent" },
                backgroundColor: "transparent",
                color: white,
                width: "100%",
              }}
            >
              <MenuOutlinedIcon />
            </Button>
          </Box>
          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Menu
              menuItemStyles={{
                suffix: () => {
                  return {
                    color: white,
                  };
                },
                label: () => {
                  return {
                    color: white,
                  };
                },
                button: ({ level }) => {
                  if (level === 0) {
                    return {
                      color: white,
                      transition: "0.25s linear",
                      "&:hover": {
                        backgroundColor: white,
                        color: blue,
                      },
                      alignItems: "center",
                    };
                  } else {
                    return {
                      color: blue,
                      transition: "0.25s linear",
                      "&:hover": {
                        backgroundColor: "rgb(215, 215, 215)",
                        color: blue,
                      },
                      alignItems: "center",
                    };
                  }
                },
                SubMenuExpandIcon: () => {
                  return {
                    display: "flex",
                    alignItems: "center",
                  };
                },
              }}
            >
              {SidebarData.map(val => {
                if (val.permission.includes(permission ?? 0)) {
                  if (val.title === "HomePage") {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {SidebarHomePageMenu.map(subVal => (
                            <MenuItem
                              key={subVal.id}
                              onClick={() =>
                                (window.location.pathname = subVal.link)
                              }
                              prefix={<p>{subVal.title}</p>}
                            />
                          ))}
                        </SubMenu>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Divider sx={{ width: "75%", borderColor: white }} />
                        </Box>
                      </>
                    );
                  } else if (val.title === "Cadastros") {
                    return (
                      <>
                        <SubMenu
                          key={val.id}
                          prefix={val.title}
                          icon={val.icon}
                        >
                          {SidebarRegisterMenu.map(subVal => (
                            <MenuItem
                              key={subVal.id}
                              onClick={() =>
                                (window.location.pathname = subVal.link)
                              }
                              prefix={<p>{subVal.title}</p>}
                            />
                          ))}
                        </SubMenu>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Divider sx={{ width: "75%", borderColor: white }} />
                        </Box>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <MenuItem
                          key={val.id}
                          onClick={() => (window.location.pathname = val.link)}
                          icon={val.icon}
                          prefix={<p>{val.title}</p>}
                        />
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Divider sx={{ width: "75%", borderColor: white }} />
                        </Box>
                      </>
                    );
                  }
                }
                return "";
              })}
            </Menu>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
          >
            <SidebarMenu />
          </Box>
        </Box>
      </Sidebar>
    </ThemeProvider>
  );
};

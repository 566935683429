import { Box, Card, useMediaQuery } from "@mui/material";
import React from "react";
import "./Solutions.css";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";

export default function Solutions() {
  const mobile = useMediaQuery("(min-width: 901px)");
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <Box className="Solutions-TitleBox">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            height: "100%",
            width: "100vw",
            gap: "0.5em"
          }}
        >
          <p id="Solutions-pTitle">O QUE FAZEMOS</p>
          <Box
            sx={{
              backgroundColor: "rgba(82, 169, 167, 1)",
              height: "0.25em",
              width: "40%",
              marginBottom: "1.5em"
            }}
          />
          {mobile ? (
            ""
          ) : (
            <Box className="Solutions-GroupCard">
              <Box className="Solutions-CardBox">
                <DevicesOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Soluções INFOR</p>
                <p id="Solutions-pText">
                  Consultoria especializada na implementação de ERP Infor.
                  Agilidade, qualidade e segurança em serviços nos melhores
                  sistemas de gestão empresarial e de gestão de armazém do
                  mercado.
                </p>
              </Box>
              <Box className="Solutions-CardBox">
                <GroupsOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Alocação de Recursos</p>
                <p id="Solutions-pText">
                  Alocação de profissionais de TI, para atuar junto ao cliente,
                  apoiando nas atividades diárias, como gerente de projetos,
                  analista de sistema, desenvolvedores, arquiteto de software,
                  entre outros.
                </p>
              </Box>
              <Box className="Solutions-CardBox">
                <DeveloperModeOutlinedIcon id="Solutions-Icon" />
                <p id="Solutions-pTitleCard">Fábrica de Software</p>
                <p id="Solutions-pText">
                  Desenvolvimento de projetos de software, aplicativos mobile,
                  aplicações web, desktop, integrações, desenvolvimento de
                  relatórios, indicadores e Dashboards.
                </p>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="Solutions-OutsideCard">
        {mobile ? (
          <Box className="Solutions-GroupCard">
            <Box className="Solutions-CardBox">
              <DevicesOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Soluções INFOR</p>
              <p id="Solutions-pText">
                Consultoria especializada na implementação de ERP Infor.
                Agilidade, qualidade e segurança em serviços nos melhores
                sistemas de gestão empresarial e de gestão de armazém do
                mercado.
              </p>
            </Box>
            <Box className="Solutions-CardBox">
              <GroupsOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Alocação de Recursos</p>
              <p id="Solutions-pText">
                Alocação de profissionais de TI, para atuar junto ao cliente,
                apoiando nas atividades diárias, como gerente de projetos,
                analista de sistema, desenvolvedores, arquiteto de software,
                entre outros.
              </p>
            </Box>
            <Box className="Solutions-CardBox">
              <DeveloperModeOutlinedIcon id="Solutions-Icon" />
              <p id="Solutions-pTitleCard">Fábrica de Software</p>
              <p id="Solutions-pText">
                Desenvolvimento de projetos de software, aplicativos mobile,
                aplicações web, desktop, integrações, desenvolvimento de
                relatórios, indicadores e Dashboards.
              </p>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

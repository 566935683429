import { ThemeProvider } from "@emotion/react";
import { Edit } from "@mui/icons-material";
import {
  Box,
  createTheme,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import ScheduleInterface from "../../types/ScheduleInterface";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export interface Row {
  id: number;
  ds_schedule: string;
  dt_ini: string;
  dt_fin: string;
  nm_company: string;
}

interface ScheduleTableInterface {
  response: (month: number, idUser: number) => Promise<void>;
  ScheduleUserInput: number;
  rows: Row[];
  handleClickDelete: () => void;
  ScheduleMonth: number;
}

const CustomNoRows = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        top: "42.5%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p id="NoRowsText">Nenhum registro encontrado</p>
        <p id="NoRowsText">Adicione um novo registro</p>
      </Box>
    </Box>
  );
};

export default function ScheduleTable({
  response,
  ScheduleUserInput,
  rows,
  handleClickDelete,
  ScheduleMonth,
}: ScheduleTableInterface) {
  const large = useMediaQuery("(min-width: 1600px)");
  const [ScheduleDesc, setScheduleDesc] = useState("");
  const [ScheduleTimeIni, setScheduleTimeIni] = React.useState<Dayjs | null>(
    null
  );
  const [ScheduleTimeFin, setScheduleTimeFin] = React.useState<Dayjs | null>(
    null
  );
  const columns: GridColDef[] = [
    {
      field: "dt_ini",
      headerName: "Início",
      headerClassName: "HeaderDataGrid",
      cellClassName: "CellDataGrid",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                value={ScheduleTimeIni}
                inputFormat={"HH:mm"}
                ampm={false}
                onChange={newValue => {
                  setScheduleTimeIni(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="primary"
                    className="TimePicker"
                    sx={{ input: { color: "white" } }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      },
    },
    {
      field: "dt_fin",
      headerName: "Fim",
      headerClassName: "HeaderDataGrid",
      cellClassName: "CellDataGrid",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                value={ScheduleTimeFin}
                inputFormat={"HH:mm"}
                ampm={false}
                onChange={newValue => {
                  setScheduleTimeFin(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="primary"
                    className="TimePicker"
                    sx={{ input: { color: "white" } }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      },
    },
    {
      field: "nm_company",
      headerName: "Cliente",
      headerClassName: "HeaderDataGrid",
      cellClassName: "CellDataGrid",
      flex: 1,
      minWidth: 275,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <TextField
              className="TimePicker"
              variant="standard"
              color="primary"
              sx={{ input: { color: "white" } }}
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="Descrição da atividade"
            />
          </ThemeProvider>
        );
      },
    },
    {
      field: "ds_schedule",
      headerName: "Descrição",
      headerClassName: "HeaderDataGrid",
      cellClassName: "CellDataGrid",
      flex: 1,
      minWidth: 450,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <TextField
              className="TimePicker"
              variant="standard"
              color="primary"
              sx={{ input: { color: "white" } }}
              InputProps={{
                disableUnderline: true,
              }}
              value={ScheduleDesc}
              defaultValue={ScheduleDesc}
              placeholder="Descrição da atividade"
            />
          </ThemeProvider>
        );
      },
    },
    {
      field: "options",
      headerName: "",
      headerClassName: "HeaderDataGrid",
      cellClassName: "CellDataGrid",
      flex: 1,
      maxWidth: 50,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <DeleteButton
          nr_sequency={parseInt(params.id.toString())}
          ScheduleMonth={ScheduleMonth}
          response={response}
          handleClickDelete={handleClickDelete}
          ScheduleUserInput={ScheduleUserInput}
        />
      ),
    },
  ];

  useEffect(() => {
    response(ScheduleMonth, ScheduleUserInput);
  }, []);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{ NoRowsOverlay: CustomNoRows }}
      className="ScheduleDataGrid"
      hideFooter={true}
    />
  );
}

interface DeleteButtonProps {
  nr_sequency: number;
  response: (month: number, idUser: number) => Promise<void>;
  ScheduleUserInput: number;
  ScheduleMonth: number;
  handleClickDelete: () => void;
}

function DeleteButton({
  nr_sequency,
  ScheduleUserInput,
  response,
  ScheduleMonth,
  handleClickDelete,
}: DeleteButtonProps) {
  const handleDelete = useCallback(() => {
    fetch(
      "http://localhost:8080/registrationservice/schedule/remove/" +
        nr_sequency,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        response(ScheduleMonth, ScheduleUserInput);
        handleClickDelete();
      });
  }, [nr_sequency]);

  return (
    <ThemeProvider theme={theme}>
      <IconButton onClick={handleDelete}>
        <DeleteOutlineIcon color="secondary" />
      </IconButton>
    </ThemeProvider>
  );
}

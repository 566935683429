import { ThemeProvider } from "@emotion/react";
import { Edit } from "@mui/icons-material";
import {
  Box,
  createTheme,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  DataGrid,
  GridCellProps,
  GridColDef,
  GridNoRowsOverlay,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import ActivityInterface from "../../types/ActivityInterface";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useQuery } from "react-query";
import { blue } from "@mui/material/colors";
import ActivityModal from "./ActivityModal";
import CompanyInterface from "../../types/CompanyInterface";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export interface Row {
  id: number;
  ds_activity: string;
  dt_time_ini: string;
  dt_time_fin: string;
  company_nr_sequency: number;
  nm_company: string;
}

interface ActivityTableInterface {
  responseChange: (newActivityDateInput: dayjs.Dayjs) => Promise<void>;
  ActivityDate: dayjs.Dayjs;
  rows: Row[];
  activities: ActivityInterface[] | undefined;
  handleClickDelete: () => void;
  handleClickUpdate: () => void;
}

const CustomNoRows = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "auto",
        }}
      >
        <p id="NoRowsText">Nenhum registro encontrado</p>
        <p id="NoRowsText">Adicione um novo registro</p>
      </Box>
    </Box>
  );
};

export default function ActivityTable({
  responseChange,
  rows,
  activities,
  handleClickDelete,
  handleClickUpdate,
  ActivityDate,
}: ActivityTableInterface) {
  const large = useMediaQuery("(min-width: 1600px)");
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [ActivityDesc, setActivityDesc] = useState("");
  const [ActivityTimeIni, setActivityTimeIni] = React.useState<Dayjs | null>(
    null
  );
  const [ActivityTimeFin, setActivityTimeFin] = React.useState<Dayjs | null>(
    null
  );
  const [ActivityCompany, setActivityCompany] = useState(0);

  const handleActivityDescInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setActivityDesc(event.target.value);
    },
    []
  );

  const handleCompanyInput = useCallback((event: SelectChangeEvent<number>) => {
    setActivityCompany(parseInt(event.target.value.toString(), 10) ?? 0);
  }, []);

  function addActivity(item: Row): Promise<Row> {
    const activityItem = activities!.find(obj => {
      return obj.id === item.id;
    });
    const Activity = {
      id: item.id,
      dt_activity: activityItem!.dt_activity,
      dt_create: activityItem!.dt_create,
      dt_create_nrec: activityItem!.dt_create_nrec,
      ds_activity:
        ActivityDesc.trim() !== "" ? ActivityDesc : activityItem!.ds_activity,
      dt_time_ini:
        ActivityTimeIni !== null
          ? ActivityTimeIni.hour() +
            ":" +
            ActivityTimeIni.minute().toString().padStart(2, "0")
          : activityItem!.dt_time_ini,
      dt_time_fin:
        ActivityTimeFin !== null
          ? ActivityTimeFin.hour() +
            ":" +
            ActivityTimeFin.minute().toString().padStart(2, "0")
          : activityItem!.dt_time_fin,
      company: {
        nr_sequency:
          ActivityCompany !== 0
            ? ActivityCompany
            : activityItem?.company.nr_sequency,
      },
      user: {
        nr_sequency: activityItem!.user.nr_sequency,
      },
    };

    return new Promise<Row>((resolve, reject) => {
      axios
        .post(
          "http://localhost:8080/registrationservice/activity/update",
          Activity
        )
        .then(res => {
          handleClickUpdate();
          setActivityDesc("");
          setActivityTimeIni(null);
          setActivityTimeFin(null);
          resolve(res.data);
        })
        .catch(() => reject(new Error("erro")));
    });
  }

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  const processRowUpdate = React.useCallback(
    async (newRow: Row) => {
      // Make the HTTP request to save in the backend
      const response = await addActivity(newRow);
      return response;
    },
    [
      addActivity,
      ActivityDesc,
      ActivityTimeIni,
      ActivityTimeFin,
      ActivityCompany,
    ]
  );

  const columns: GridColDef[] = [
    {
      field: "dt_time_ini",
      headerName: "Início",
      headerClassName: "Activity-HeaderDataGrid",
      cellClassName: "Activity-CellDataGrid",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                value={ActivityTimeIni}
                inputFormat={"HH:mm"}
                ampm={false}
                onChange={newValue => {
                  setActivityTimeIni(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="primary"
                    className="TimePicker"
                    sx={{ input: { color: "white" } }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      },
    },
    {
      field: "dt_time_fin",
      headerName: "Fim",
      headerClassName: "Activity-HeaderDataGrid",
      cellClassName: "Activity-CellDataGrid",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                value={ActivityTimeFin}
                inputFormat={"HH:mm"}
                ampm={false}
                onChange={newValue => {
                  setActivityTimeFin(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    color="primary"
                    className="TimePicker"
                    sx={{ input: { color: "white" } }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </ThemeProvider>
        );
      },
    },
    {
      field: "nm_company",
      headerName: "Cliente",
      headerClassName: "Activity-HeaderDataGrid",
      cellClassName: "Activity-CellDataGrid",
      flex: 1,
      minWidth: 275,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <FormControl fullWidth>
              <Select
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  textAlign: "left",
                  color: "white",
                }}
                inputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                color="secondary"
                onChange={handleCompanyInput}
              >
                {repoClientes?.map(val => {
                  return (
                    <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                      {val.nm_company}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ThemeProvider>
        );
      },
    },
    {
      field: "ds_activity",
      headerName: "Descrição",
      headerClassName: "Activity-HeaderDataGrid",
      cellClassName: "Activity-CellDataGrid",
      flex: 1,
      minWidth: 450,
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      renderEditCell: () => {
        return (
          <ThemeProvider theme={theme}>
            <TextField
              className="TimePicker"
              variant="standard"
              color="primary"
              sx={{ input: { color: "white" } }}
              InputProps={{
                disableUnderline: true,
              }}
              defaultValue={ActivityDesc}
              onChange={handleActivityDescInput}
              placeholder="Descrição da atividade"
            />
          </ThemeProvider>
        );
      },
    },
    {
      field: "options",
      headerName: "",
      headerClassName: "Activity-HeaderDataGrid",
      cellClassName: "Activity-CellDataGrid",
      flex: 1,
      maxWidth: 50,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <DeleteButton
          nr_sequency={parseInt(params.id.toString())}
          responseChange={responseChange}
          handleClickDelete={handleClickDelete}
          ActivityDate={ActivityDate}
        />
      ),
    },
  ];

  useEffect(() => {
    responseChange(ActivityDate);
  }, []);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      editMode="row"
      processRowUpdate={processRowUpdate}
      components={{ NoRowsOverlay: CustomNoRows }}
      hideFooter={true}
    />
  );
}

interface DeleteButtonProps {
  nr_sequency: number;
  responseChange: (newActivityDateInput: dayjs.Dayjs) => Promise<void>;
  handleClickDelete: () => void;
  ActivityDate: dayjs.Dayjs;
}

function DeleteButton({
  nr_sequency,
  responseChange,
  handleClickDelete,
  ActivityDate,
}: DeleteButtonProps) {
  const handleDelete = useCallback(() => {
    fetch(
      "http://localhost:8080/registrationservice/activity/remove/" +
        nr_sequency,
      {
        method: "delete",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(retorno => retorno.json())
      .then(() => {
        responseChange(ActivityDate);
        handleClickDelete();
      });
  }, [nr_sequency]);

  return (
    <ThemeProvider theme={theme}>
      <IconButton onClick={handleDelete}>
        <DeleteOutlineIcon color="secondary" />
      </IconButton>
    </ThemeProvider>
  );
}

import React from "react";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

const permAdministrador = 1;
const permCoordenador = 2;
const permGeral = 3;

export const SidebarData = [
  {
    id: 1,
    title: "Atividades",
    icon: <WorkHistoryOutlinedIcon />,
    link: "/Portal/Activity",
    permission: [permAdministrador, permCoordenador, permGeral],
  },
  {
    id: 2,
    title: "Agendamento",
    icon: <CalendarMonthOutlinedIcon />,
    link: "/Portal/Schedule",
    permission: [permAdministrador, permCoordenador, permGeral],
  },
  {
    id: 3,
    title: "Cadastros",
    icon: <AccountBoxOutlinedIcon />,
    link: "",
    permission: [permAdministrador],
  },
  {
    id: 4,
    title: "HomePage",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "",
    permission: [permAdministrador, permCoordenador],
  },
];

export const SidebarHomePageMenu = [
  {
    id: 1,
    title: "Novidades",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "/Portal/HomePageNews",
    permission: [permAdministrador, permCoordenador],
  },
  {
    id: 2,
    title: "Newsletter",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "/Portal/HomePageNewsletter",
    permission: [permAdministrador, permCoordenador],
  },
];

export const SidebarRegisterMenu = [
  {
    id: 1,
    title: "Usuários",
    icon: <GroupOutlinedIcon />,
    link: "/Portal/Users",
    permission: [permAdministrador],
  },
  {
    id: 2,
    title: "Clientes",
    icon: <ApartmentOutlinedIcon />,
    link: "/Portal/Company",
    permission: [permAdministrador],
  },
  {
    id: 3,
    title: "Perfil",
    icon: <AccountBoxOutlinedIcon />,
    link: "/Portal/ProfileUser",
    permission: [permAdministrador],
  },
];

import React, { useCallback, useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  styled,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ScheduleTable, { Row } from "./ScheduleTable";
import moment from "moment";
import axios from "axios";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import "./Schedule.css";
import { DateObject } from "react-multi-date-picker";
import ScheduleInterface from "../../types/ScheduleInterface";
import Navbar from "./Navbar/Navbar";
import dayjs, { Dayjs } from "dayjs";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const ScheduleButton = styled(Button)({
  textTransform: "none",
  color: "white",
  fontFamily: "Trebuchet MS, sans-serif",
});

export default function Schedule() {
  const Mobile = useMediaQuery("(min-width: 901px)");
  const { permission } = useContext(CurrentUserContext);
  const [idSelecionado, setIdSelecionado] = useState<number>(0);
  const [rows, setRows] = useState<Row[]>([]);
  const loggedUserPermission = parseInt(
    localStorage.getItem("currentUserPermission") ?? "-1"
  );

  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const handleClickAdd = useCallback(() => {
    setOpenAdd(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    setOpenDelete(true);
  }, []);

  const handleClickUpdate = useCallback(() => {
    setOpenUpdate(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpenAdd(false);
    setOpenDelete(false);
    setOpenUpdate(false);
  }, []);

  const loggedUserId =
    parseInt(localStorage.getItem("currentUserId") ?? "-1", 10) ?? -1;
  const [ScheduleMonth, setScheduleMonth] = useState(dayjs().month());
  const [ScheduleMonthPicker, setScheduleMonthPicker] = React.useState<Dayjs>(
    dayjs()
  );

  const handleCalendarMonth = (newScheduleMonthPicker: dayjs.Dayjs | null) => {
    setScheduleMonthPicker(newScheduleMonthPicker!);
    setScheduleMonth(dayjs(newScheduleMonthPicker).month());
    scheduleList(dayjs(newScheduleMonthPicker).month(), ScheduleUserInput);
  };

  const [ScheduleUserInput, setScheduleUserInput] = useState(0);

  const handleUserInput = useCallback((event: SelectChangeEvent<number>) => {
    setScheduleUserInput(parseInt(event.target.value.toString(), 10) ?? 0);
    scheduleList(ScheduleMonth, parseInt(event.target.value.toString(), 10) ?? 0);
  }, [ScheduleMonth]);

  const scheduleList = async (month: number, idUser: number) => {
    await axios
      .get<ScheduleInterface[]>(
        "http://localhost:8080/registrationservice/schedule/list"
      )
      .then(res => {
        const filtrado = res.data.filter(
          filter =>
            (loggedUserPermission === 1 ? idUser : loggedUserId) ===
              filter.user.nr_sequency &&
              month === dayjs(filter.dt_ini, "DD/MM/YYYY").month()
        );
        const data = filtrado.map(schedule => ({
          id: schedule.id,
          ds_schedule: schedule.ds_schedule,
          dt_ini: schedule.dt_ini,
          dt_fin: schedule.dt_fin,
          nm_company: schedule.company.nm_company,
        }));
        setRows(data);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: Mobile ? "100%" : "92.5svh",
          padding: "0.5em",
          gap: "1em",
        }}
      >
        <Navbar
          handleClickAdd={handleClickAdd}
          scheduleList={scheduleList}
          handleCalendarMonth={handleCalendarMonth}
          ScheduleMonthPicker={ScheduleMonthPicker}
          handleUserInput={handleUserInput}
          ScheduleUserInput={ScheduleUserInput}
        />
        <Box className="Schedule-Wrapper">
          <ThemeProvider theme={theme}>
            <ScheduleTable
              response={scheduleList}
              ScheduleMonth={ScheduleMonth}
              ScheduleUserInput={ScheduleUserInput}
              rows={rows}
              handleClickDelete={handleClickDelete}
            />
          </ThemeProvider>
        </Box>
      </Box>
      <Snackbar
        open={openAdd}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          Usuário adicionado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openDelete}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<DeleteOutlineOutlinedIcon />}
          severity="success"
          variant="filled"
          color="error"
        >
          Usuário excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={openUpdate}
        onClick={handleClose}
        onClose={handleClose}
        autoHideDuration={3000}
      >
        <Alert
          icon={<TaskOutlinedIcon />}
          severity="success"
          variant="filled"
          color="info"
        >
          Usuário atualizado com sucesso
        </Alert>
      </Snackbar>
    </>
  );
}

import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  Tooltip,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import "./Navbar.css";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import dayjs, { Dayjs } from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleModal from "../ScheduleModal";
import { CurrentUserContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import { useQuery } from "react-query";
import UserInterface from "../../../types/UserInterface";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
  ptBR,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface NavbarInterface {
  scheduleList: (month: number, idUser: number) => Promise<void>;
  handleClickAdd: () => void;
  handleUserInput: (event: SelectChangeEvent<number>) => void;
  ScheduleUserInput: number;
  handleCalendarMonth: (newScheduleMonthPicker: dayjs.Dayjs | null) => void;
  ScheduleMonthPicker: dayjs.Dayjs;
}

export default function Navbar({
  scheduleList,
  handleClickAdd,
  handleUserInput,
  ScheduleUserInput,
  handleCalendarMonth,
  ScheduleMonthPicker,
}: NavbarInterface) {
  const { permission } = useContext(CurrentUserContext);
  const Mobile = useMediaQuery("(min-width: 901px)");
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const AddSchedule = useCallback((): void => {
    setOpenCriar(true);
  }, []);

  const handleCriarClose = useCallback((): void => {
    setOpenCriar(false);
  }, []);

  const [openCriar, setOpenCriar] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: blue,
      },
      secondary: {
        main: "#ffffff",
      },
    },
  });

  const { data: repoUsuarios } = useQuery<UserInterface[]>(
    "User",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/user/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <>
      {Mobile ? (
        <Box className="NavbarSchedule-Default">
          <p id="NavbarSchedule-pTitle">Agendamento</p>
          <ThemeProvider theme={theme}>
            <Box className="NavbarSchedule-Box">
              <IconButton
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  padding: "0em 0.75em",
                }}
                size="small"
              >
                <FilterAltOutlinedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                      width: 30,
                      height: 30,
                      ml: -0.5,
                      mr: 1,
                    },
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                {permission === 1 ? (
                  <FormControl
                    fullWidth
                    sx={{
                      p: "2px 4px",
                    }}
                  >
                    <InputLabel>
                      <p>Usuário</p>
                    </InputLabel>
                    <Select
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        textAlign: "left",
                      }}
                      fullWidth
                      size="small"
                      color="primary"
                      onChange={handleUserInput}
                      label={<p>Usuário</p>}
                      defaultValue={ScheduleUserInput}
                    >
                      {repoUsuarios?.map(val => {
                        return (
                          <MenuItem
                            key={val.nr_sequency}
                            value={val.nr_sequency}
                          >
                            {val.nm_person}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  ""
                )}
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={ptBR}
                  localeText={
                    ptBR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    views={["month"]}
                    value={ScheduleMonthPicker}
                    onChange={handleCalendarMonth}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Menu>
              <IconButton
                onClick={AddSchedule}
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: 2,
                  boxShadow: 5,
                  gap: 1,
                  fontSize: "1.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon />
                <p id="NavbarSchedule-pButton">Novo agendamento</p>
              </IconButton>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ScheduleModal
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                  scheduleList={scheduleList}
                  ScheduleUserInput={ScheduleUserInput}
                />
              </Modal>
            </Box>
          </ThemeProvider>
        </Box>
      ) : (
        <Box className="NavbarSchedule-Default">
          <p id="NavbarSchedule-pTitle">Agendamento</p>
          <Box className="NavbarSchedule-Box">
            <ThemeProvider theme={theme}>
              <Tooltip title="Account settings">
                <IconButton
                  className="CreateButton-Schedule"
                  onClick={handleClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    "&:hover": { backgroundColor: "#c2c2c2" },
                  }}
                  size="small"
                >
                  <MoreVertOutlinedIcon
                    sx={{ fontSize: 30 }}
                    color="secondary"
                  />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme}>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  "& .MuiAvatar-root": {
                    width: 30,
                    height: 30,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {permission === 1 || permission === 2 ? (
                <FormControl
                  fullWidth
                  sx={{
                    p: "2px 4px",
                  }}
                >
                  <InputLabel>
                    <p>Usuário</p>
                  </InputLabel>
                  <Select
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      textAlign: "left",
                    }}
                    fullWidth
                    size="small"
                    color="primary"
                    onChange={handleUserInput}
                    label={<p>Usuário</p>}
                    defaultValue={ScheduleUserInput}
                  >
                    {repoUsuarios?.map(val => {
                      return (
                        <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                          {val.nm_person}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ptBR}
                localeText={
                  ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  views={["month"]}
                  value={ScheduleMonthPicker}
                  onChange={handleCalendarMonth}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
              <MenuItem sx={{ ml: 1.1, gap: 1 }} onClick={AddSchedule}>
                <AddCircleOutlineIcon color="primary" sx={{ fontSize: 22.5 }} />
                <p id="NavbarSchedule-pMenu">Novo agendamento</p>
              </MenuItem>
              <Modal open={openCriar} onClose={handleCriarClose}>
                <ScheduleModal
                  handleCriarClose={handleCriarClose}
                  handleClickAdd={handleClickAdd}
                  scheduleList={scheduleList}
                  ScheduleUserInput={ScheduleUserInput}
                />
              </Modal>
            </Menu>
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}

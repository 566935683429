import React, { useState } from "react";
import { Box, Button, Link, useMediaQuery } from "@mui/material";
import "./Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  const mobile = useMediaQuery("(min-width: 901px)");

  return (
    <>
      {mobile ? (
        <Box className="Footer-OutsideBox">
          <img
            src="Images/branding/a marca/sem fundo/cloup one_marca principal_02_crop.png"
            alt="placeholder"
            id="Footer-Image"
          />
          <Box className="Footer-ContactBox">
            <p id="Footer-pTitle">Contato</p>
            <Link href="mailto:contato@cloupone.com.br?body: Conteudo do email" underline="none">
              <p id="Footer-pText">contato@cloupone.com.br</p>
            </Link>
            <Link
              href="https://wa.me/31701400?text=Bom%20dia!%20Estou%20entrando%20em%20contato%20por%20que..."
              underline="none"
            >
              <p id="Footer-pText">+55 47 99119-0089</p>
            </Link>
          </Box>
          <Box className="Footer-SocialBox">
            <p id="Footer-pTitle">Redes Sociais</p>
            <Button
              href="https://www.linkedin.com/company/incortecti/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon id="Footer-pText" />
              <p id="Footer-pText">Linkedin</p>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box className="Footer-OutsideBox">
          <Box className="Footer-ContactBox">
            <p id="Footer-pTitle">Contato</p>
            <Link href="mailto:contato@cloupone.com.br" underline="none">
              <p id="Footer-pText">contato@cloupone.com.br</p>
            </Link>
            <Link
              href="https://wa.me/31701400?text=Bom%20dia!%20Estou%20entrando%20em%20contato%20por%20que..."
              underline="none"
            >
              <p id="Footer-pText">+55 47 99119-0089</p>
            </Link>
          </Box>
          <Box className="Footer-SocialBox">
            <p id="Footer-pTitle">Redes Sociais</p>
            <Button
              href="https://www.linkedin.com/company/incortecti/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon id="Footer-pText" />
              <p id="Footer-pText">Linkedin</p>
            </Button>
          </Box>
          <img
            src="Images/branding/a marca/sem fundo/cloup one_marca principal_02_crop.png"
            alt="placeholder"
            id="Footer-Image"
          />
        </Box>
      )}
    </>
  );
}

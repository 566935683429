import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import HomePage from "./components/HomePage/HomePage";
import Activity from "./components/Portal/components/Activity/Activity";
import Company from "./components/Portal/components/Company/Company";
import ProfileUser from "./components/Portal/components/ProfileUser/ProfileUser";
import AppPortal from "./components/Portal/AppPortal";
import ProtectedRoute from "./components/Portal/ProtectedRoute";
import User from "./components/Portal/components/User/User";
import Schedule from "./components/Portal/components/Schedule/Schedule";
import RAT from "./components/Portal/components/Activity/RAT/RAT";
import Login from "./components/Portal/components/Login/Login";
import HomePageNewsletter from "./components/Portal/components/HomePageNewsletter/HomePageNewsletter";
import HomePageNovidades from "./components/Portal/components/HomePageNovidades/HomePageNovidades";
import NewsletterPage from "./components/HomePage/Pages/NewsletterPage/NewsletterPage";
import NewsPage from "./components/HomePage/Pages/NewsPage/NewsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "NewsletterPage/:id",
        element: <NewsletterPage />,
      },
      {
        path: "NewsPage/:id",
        element: <NewsPage />,
      },
    ],
  },
  {
    path: "/Portal",
    element: (
      <ProtectedRoute>
        <AppPortal />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "Users",
        element: <User />,
      },
      {
        path: "Activity",
        element: <Activity />,
      },
      {
        path: "Schedule",
        element: <Schedule />,
      },
      {
        path: "Company",
        element: <Company />,
      },
      {
        path: "ProfileUser",
        element: <ProfileUser />,
      },
      {
        path: "HomePageNews",
        element: <HomePageNovidades />,
      },
      {
        path: "HomePageNewsletter",
        element: <HomePageNewsletter />,
      },
    ],
  },
  {
    path: "Login",
    element: <Login />,
  },
]);

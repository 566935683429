import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import React, { SyntheticEvent, useCallback, useContext } from "react";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import "../Sidebar/Sidebar.css";
import {
  SidebarData,
  SidebarHomePageMenu,
  SidebarRegisterMenu,
} from "../Sidebar/SidebarData";
import { Box, Divider } from "@mui/material";

export const ProfileDrawer = () => {
  const { permission } = useContext(CurrentUserContext);
  const blue = "rgba(53, 68, 145, 1)";
  const white = "#ffffff";
  const redirect = useCallback((e: SyntheticEvent<HTMLElement>) => {
    window.location.pathname = e.currentTarget.dataset.link ?? "";
  }, []);

  return (
    <Menu
      menuItemStyles={{
        suffix: () => {
          return {
            color: blue,
          };
        },
        label: () => {
          return {
            color: blue,
          };
        },
        button: () => {
          return {
            color: blue,
          };
        },
        icon: () => {
          return {
            color: blue,
          };
        },
        SubMenuExpandIcon: () => {
          return {
            position: "relative",
            bottom: "5%",
          };
        },
      }}
    >
      {SidebarData.map(val => {
        if (val.permission.includes(permission ?? 0)) {
          if (val.title === "HomePage") {
            return (
              <>
                <SubMenu key={val.id} prefix={val.title} icon={val.icon}>
                  {SidebarHomePageMenu.map(subVal => (
                    <MenuItem
                      key={subVal.id}
                      onClick={() => (window.location.pathname = subVal.link)}
                      prefix={<p>{subVal.title}</p>}
                    />
                  ))}
                </SubMenu>
              </>
            );
          } else if (val.title === "Cadastros") {
            return (
              <>
                <SubMenu key={val.id} prefix={val.title} icon={val.icon}>
                  {SidebarRegisterMenu.map(subVal => (
                    <MenuItem
                      key={subVal.id}
                      onClick={() => (window.location.pathname = subVal.link)}
                      prefix={<p>{subVal.title}</p>}
                    />
                  ))}
                </SubMenu>
              </>
            );
          } else {
            return (
              <MenuItem
                key={val.id}
                data-link={val.link}
                onClick={redirect}
                icon={val.icon}
                prefix={<p>{val.title}</p>}
                rootStyles={{ height: "15%" }}
              ></MenuItem>
            );
          }
        }
        return "";
      })}
    </Menu>
  );
};

import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import {
  createTheme,
  Button,
  Box,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import React, { useCallback, useContext, useState } from "react";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
  useQuery,
} from "react-query";
import { CurrentUserContext } from "../../contexts/ContextProvider";
import ActivityInterface from "../../types/ActivityInterface";
import CompanyInterface from "../../types/CompanyInterface";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface ModalProps {
  ActivityDate: Dayjs | null;
  handleCriarClose: () => void;
  responseChange: (newActivityDateInput: dayjs.Dayjs) => Promise<void>;
  handleClickAdd: () => void;
}

export default function ActivityModal({
  ActivityDate,
  handleCriarClose,
  responseChange,
  handleClickAdd,
}: ModalProps) {
  const blue = "rgba(53, 68, 145, 1)";
  const staleTimeFirst = 1000;
  const staleTimeSec = 180;
  const { currentUserId } = useContext(CurrentUserContext);
  const [ActivityDescriptionInput, setActivityDescriptionInput] = useState("");
  const [ActivityTimeIni, setActivityTimeIni] = React.useState<Dayjs | null>(
    null
  );
  const [ActivityTimeFin, setActivityTimeFin] = React.useState<Dayjs | null>(
    null
  );
  const [ActivityCompanyInput, setActivityCompanyInput] = useState(0);

  const [ActivityDescriptionInputError, setActivityDescriptionInputError] =
    useState(false);
  const [ActivityTimeIniError, setActivityTimeIniError] = useState(false);
  const [ActivityTimeFinError, setActivityTimeFinError] = useState(false);
  const [ActivityCompanyInputError, setActivityCompanyInputError] =
    useState(false);
  const [dsMsgInputError, setDsMsgInputError] = useState("");

  const handleActivityDescriptionInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setActivityDescriptionInputError(false);
      }
      setActivityDescriptionInput(event.target.value);
    },
    []
  );

  const handleActivityTimeIni = useCallback(
    (newActivityTimeIni: Dayjs | null) => {
      if (newActivityTimeIni !== null) {
        setActivityTimeIniError(false);
      }
      setActivityTimeIni(newActivityTimeIni);
    },
    []
  );

  const handleActivityTimeFin = useCallback(
    (newActivityTimeFin: Dayjs | null) => {
      if (newActivityTimeFin !== null) {
        setActivityTimeFinError(false);
      }
      setActivityTimeFin(newActivityTimeFin);
    },
    []
  );

  const handleCompanyIdInput = useCallback(
    (event: SelectChangeEvent<number>) => {
      if (event.target.value !== 0) {
        setActivityCompanyInputError(false);
      }
      setActivityCompanyInput(parseInt(event.target.value.toString(), 10) ?? 0);
    },
    []
  );

  const [data] = React.useState<string | null>(moment().format("DD/MM/yyyy"));

  const addActivity = () => {
    const Activity = {
      ds_activity: ActivityDescriptionInput,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
      dt_activity: ActivityDate?.format("DD/MM/YYYY"),
      dt_time_ini: ActivityTimeIni?.format("HH:mm"),
      dt_time_fin: ActivityTimeFin?.format("HH:mm"),
      user: {
        nr_sequency: currentUserId,
      },
      company: {
        nr_sequency: ActivityCompanyInput,
      },
    };

    axios
      .post("http://localhost:8080/registrationservice/activity/add", Activity)
      .then(() => {
        responseChange(ActivityDate!);
        handleCriarClose();
        handleClickAdd();
      });
  };

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (ActivityTimeIni == null) {
      setActivityTimeIniError(true);
      setDsMsgInputError("Favor informar o horário inicial");
      handleClick();
    } else if (ActivityTimeFin == null) {
      setActivityTimeFinError(true);
      setDsMsgInputError("Favor informar o horário final");
      handleClick();
    } else if (ActivityDescriptionInput === "") {
      setActivityDescriptionInputError(true);
      setDsMsgInputError("Favor informar a descrição");
      handleClick();
    } else if (ActivityCompanyInput === 0) {
      setActivityCompanyInputError(true);
      setDsMsgInputError("Favor informar o Cliente");
      handleClick();
    } else {
      addActivity();
    }
  }, [
    ActivityDescriptionInput,
    ActivityTimeIni,
    ActivityTimeFin,
    ActivityDate,
    data,
    currentUserId,
    ActivityCompanyInput,
  ]);

  const { data: repoClientes } = useQuery<CompanyInterface[]>(
    "company",
    async () => {
      const response = await axios.get(
        "http://localhost:8080/registrationservice/company/list"
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: staleTimeFirst * staleTimeSec,
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            multiFunctions();
          }
        }}
      >
        <Box className="ModalActivity-Main">
          <Box className="ModalActivity-Wrapper">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p id="ModalActivity-pTitle">Inserir novo cliente</p>
              <IconButton onClick={handleCriarClose}>
                <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
              </IconButton>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className="ModalActivity-TimePickerBox">
                <TimePicker
                  value={ActivityTimeIni}
                  label={<p>horário de início</p>}
                  inputFormat={"HH:mm"}
                  onChange={handleActivityTimeIni}
                  disableOpenPicker={true}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      color="primary"
                      error={ActivityTimeIniError}
                      fullWidth
                    />
                  )}
                />
                <TimePicker
                  value={ActivityTimeFin}
                  label={<p>horário de término</p>}
                  inputFormat={"HH:mm"}
                  onChange={handleActivityTimeFin}
                  disableOpenPicker={true}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      color="primary"
                      error={ActivityTimeFinError}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
            <TextField
              error={ActivityDescriptionInputError}
              fullWidth
              onChange={handleActivityDescriptionInput}
              size="small"
              label={<p>Descrição da atividade</p>}
              variant="standard"
              color="primary"
              multiline
              rows={5}
              maxRows={5}
            />
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <InputLabel>
                <p>Cliente</p>
              </InputLabel>
              <Select
                error={ActivityCompanyInputError}
                label={<p>Cliente</p>}
                onChange={handleCompanyIdInput}
                fullWidth
                color="primary"
                variant="standard"
              >
                {repoClientes?.map(val => {
                  return (
                    <MenuItem key={val.nr_sequency} value={val.nr_sequency}>
                      {val.nm_company}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
              <Button
                size="small"
                onClick={multiFunctions}
                disableRipple
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: blue,
                  "&:hover": { backgroundColor: "white", color: blue },
                  borderRadius: "10px",
                  boxShadow: 5,
                  textTransform: "none",
                  padding: "0.25em 5em"
                }}
              >
                <p id="ModalActivity-pButton">Inserir atividade</p>
              </Button>
            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
        <Alert severity="error" variant="filled">
          {dsMsgInputError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

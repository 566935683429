import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useCallback, useState } from "react";
import {
  Button,
  Box,
  Alert,
  Snackbar,
  MenuItem,
  Select,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from "react-query";
import HomePageInterface from "../../types/HomePageInterface";
import "./HomePageNovidades.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(53, 68, 145, 1)",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

interface HomePageNovidadesModalProps {
  HomePageNovidadesRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<HomePageInterface[]>>;
  handleCriarClose: () => void;
  handleClickAdd: () => void;
}

export default function HomePageNovidadesModal({
  HomePageNovidadesRefetch,
  handleCriarClose,
  handleClickAdd,
}: HomePageNovidadesModalProps) {
  const blue = "rgba(53, 68, 145, 1)";

  const [openConteudo, setOpenConteudo] = useState(false);

  const handleConteudo = useCallback((): void => {
    setOpenConteudo(true);
  }, []);

  const handleConteudoClose = useCallback((): void => {
    setOpenConteudo(false);
  }, []);

  const [HomePageNovidadesTitulo, setHomePageNovidadesTitulo] = useState("");
  const [HomePageNovidadesConteudo1, setHomePageNovidadesConteudo1] =
    useState("");
  const [HomePageNovidadesConteudo2, setHomePageNovidadesConteudo2] =
    useState("");
  const [HomePageNovidadesConteudo3, setHomePageNovidadesConteudo3] =
    useState("");
  const [HomePageNovidadesConteudo4, setHomePageNovidadesConteudo4] =
    useState("");
  const [HomePageNovidadesConteudo5, setHomePageNovidadesConteudo5] =
    useState("");
  const [HomePageNovidadesAdicional, setHomePageNovidadesAdicional] =
    useState("");

  const [HomePageNovidadesTituloError, setHomePageNovidadesTituloError] =
    useState(false);
  const [HomePageNovidadesConteudoError, setHomePageNovidadesConteudoError] =
    useState(false);
  const [HomePageNovidadesAdicionalError, setHomePageNovidadesAdicionalError] =
    useState(false);
  const [dsMsgError, setDsMsgError] = useState("");

  const handleHomePageNovidadesTitulo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesTituloError(false);
      }
      setHomePageNovidadesTitulo(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo1 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo1(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo2 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo2(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo3 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo3(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo4 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo4(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesConteudo5 = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesConteudoError(false);
      }
      setHomePageNovidadesConteudo5(event.target.value);
    },
    []
  );

  const handleHomePageNovidadesAdicional = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== "") {
        setHomePageNovidadesAdicionalError(false);
      }
      setHomePageNovidadesAdicional(event.target.value);
    },
    []
  );

  const [data] = React.useState<string | null>(
    moment().format("DD/MM/yyyy HH:mm")
  );

  const addHomePageNovidades = useCallback(() => {
    const HomePageNovidades = {
      dsTitulo: HomePageNovidadesTitulo,
      dsConteudo: HomePageNovidadesConteudo1,
      dsConteudo2: HomePageNovidadesConteudo2,
      dsConteudo3: HomePageNovidadesConteudo3,
      dsConteudo4: HomePageNovidadesConteudo4,
      dsConteudo5: HomePageNovidadesConteudo5,
      dsAdicional: HomePageNovidadesAdicional,
      dt_create: data,
      dt_create_nrec: data,
      nr_user: 1,
      nr_user_nrec: 1,
    };

    axios
      .post(
        "http://localhost:8080/registrationservice/homepagenews/add",
        HomePageNovidades
      )
      .then(() => {
        HomePageNovidadesRefetch();
        handleCriarClose();
        handleClickAdd();
      });
  }, [
    HomePageNovidadesTitulo,
    HomePageNovidadesConteudo1,
    HomePageNovidadesConteudo2,
    HomePageNovidadesConteudo3,
    HomePageNovidadesConteudo4,
    HomePageNovidadesConteudo5,
    HomePageNovidadesAdicional,
    data,
  ]);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const multiFunctions = useCallback(() => {
    if (HomePageNovidadesTitulo === "") {
      setHomePageNovidadesTituloError(true);
      setDsMsgError("Favor informar o título do HPS");
      handleClick();
    } else if (HomePageNovidadesConteudo1 === "") {
      setHomePageNovidadesConteudoError(true);
      setDsMsgError("Favor informar o conteúdo");
      handleClick();
    } else if (HomePageNovidadesAdicional === "") {
      setHomePageNovidadesAdicionalError(true);
      setDsMsgError("Favor informar o adicional");
      handleClick();
    } else {
      addHomePageNovidades();
    }
  }, [
    HomePageNovidadesTitulo,
    HomePageNovidadesConteudo1,
    HomePageNovidadesConteudo2,
    HomePageNovidadesConteudo3,
    HomePageNovidadesConteudo4,
    HomePageNovidadesConteudo5,
    HomePageNovidadesAdicional,
    data,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          multiFunctions();
        }
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Inserir novo notícia</p>
            <IconButton onClick={handleCriarClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <TextField
            error={HomePageNovidadesTituloError}
            fullWidth
            onChange={handleHomePageNovidadesTitulo}
            size="small"
            label={<p>Título da notícia</p>}
            variant="standard"
          />
          <Button
            onClick={handleConteudo}
            size="small"
            disableRipple
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
              color: HomePageNovidadesConteudoError ? "red" : blue,
              borderColor: HomePageNovidadesConteudoError ? "red" : blue,
              "&:hover": { backgroundColor: blue, color: "white" },
              borderRadius: "10px",
              textTransform: "none",
              padding: "0.25em 5em",
            }}
            fullWidth
          >
            <PostAddOutlinedIcon />
            <p id="ModalHomePageNovidades-pButton">Adicionar conteúdo</p>
          </Button>
          <Modal open={openConteudo} onClose={handleConteudoClose}>
            <ConteudoModal
              handleHomePageNovidadesConteudo1={
                handleHomePageNovidadesConteudo1
              }
              handleHomePageNovidadesConteudo2={
                handleHomePageNovidadesConteudo2
              }
              handleHomePageNovidadesConteudo3={
                handleHomePageNovidadesConteudo3
              }
              handleHomePageNovidadesConteudo4={
                handleHomePageNovidadesConteudo4
              }
              handleHomePageNovidadesConteudo5={
                handleHomePageNovidadesConteudo5
              }
              HomePageNovidadesConteudo1={HomePageNovidadesConteudo1}
              HomePageNovidadesConteudo2={HomePageNovidadesConteudo2}
              HomePageNovidadesConteudo3={HomePageNovidadesConteudo3}
              HomePageNovidadesConteudo4={HomePageNovidadesConteudo4}
              HomePageNovidadesConteudo5={HomePageNovidadesConteudo5}
              handleConteudoClose={handleConteudoClose}
            />
          </Modal>
          <TextField
            error={HomePageNovidadesAdicionalError}
            fullWidth
            onChange={handleHomePageNovidadesAdicional}
            size="small"
            label={<p>Adicional</p>}
            variant="standard"
            multiline
            rows={2}
            maxRows={2}
          />
          <ThemeProvider theme={theme}>
            <Button
              size="small"
              onClick={multiFunctions}
              disableRipple
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: blue,
                "&:hover": { backgroundColor: "white", color: blue },
                borderRadius: "10px",
                boxShadow: 5,
                textTransform: "none",
                padding: "0.25em 5em",
              }}
            >
              <p id="ModalHomePageNovidades-pButton">Inserir notícia</p>
            </Button>
          </ThemeProvider>
        </Box>
        <Snackbar open={open} onClick={handleClose} autoHideDuration={2000}>
          <Alert severity="error" variant="filled">
            {dsMsgError}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}

function ConteudoModal(props: {
  handleHomePageNovidadesConteudo1: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo2: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo3: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo4: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleHomePageNovidadesConteudo5: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleConteudoClose: () => void;
  HomePageNovidadesConteudo1: string;
  HomePageNovidadesConteudo2: string;
  HomePageNovidadesConteudo3: string;
  HomePageNovidadesConteudo4: string;
  HomePageNovidadesConteudo5: string;
}) {
  const {
    handleHomePageNovidadesConteudo1,
    handleHomePageNovidadesConteudo2,
    handleHomePageNovidadesConteudo3,
    handleHomePageNovidadesConteudo4,
    handleHomePageNovidadesConteudo5,
    handleConteudoClose,
    HomePageNovidadesConteudo1,
    HomePageNovidadesConteudo2,
    HomePageNovidadesConteudo3,
    HomePageNovidadesConteudo4,
    HomePageNovidadesConteudo5
  } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Box className="ModalHomePageNovidades-Main">
        <Box className="ModalHomePageNovidades-Wrapper">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p id="ModalHomePageNovidades-pTitle">Conteúdo</p>
            <IconButton onClick={handleConteudoClose}>
              <CloseOutlinedIcon sx={{ color: "rgba(53, 68, 145, 1)" }} />
            </IconButton>
          </Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>1° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo1}
                defaultValue={HomePageNovidadesConteudo1}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>2° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo2}
                defaultValue={HomePageNovidadesConteudo2}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>3° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo3}
                defaultValue={HomePageNovidadesConteudo3}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>4° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo4}
                defaultValue={HomePageNovidadesConteudo4}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            disableGutters
            sx={{
              width: "100%",
              borderRadius: "5px",
              "&:before": {
                height: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p>5° Parágrafo</p>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                onChange={handleHomePageNovidadesConteudo5}
                defaultValue={HomePageNovidadesConteudo5}
                size="small"
                label={<p>Conteúdo da notícia</p>}
                variant="standard"
                multiline
                rows={5}
                maxRows={20}
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}
